import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { DynamicThemeProvider, Loading, Snackbar } from '@guryou/html-components';
import { feelTheme } from '@guryou/html-theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ResetPasswordDeal from 'containers/ResetPasswordDeal';
import { ConfirmationError, ConfirmationSuccess } from 'modules/confirmations/';
import { UserConfirmation } from 'modules/user';
import Widget from 'modules/widget';
import moment from 'moment-timezone';
import Routes from 'Routes';
const App = () => {
  return (
    <DynamicThemeProvider theme={feelTheme}>
      <CssBaseline />
      <Snackbar
        maxSnack={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Loading />
          <Router>
            <Switch>
              <Route path="/reset/:email/:code" component={ResetPasswordDeal} libInstance={moment} />
              <Route path="/user-confirmation/:code" component={UserConfirmation} />
              <Route path="/reservation-success" component={() => <ConfirmationSuccess type="reservation" />} />
              <Route path="/subscription-success" component={() => <ConfirmationSuccess type="subscription" />} />
              <Route path="/reservation-confirmation-error" component={() => <ConfirmationError type="reservation" />} />
              <Route path="/subscription-confirmation-error" component={() => <ConfirmationError type="subscription" />} />
              <Route path="/website-integration/:integrationId" component={Widget} />
              <Route path="/" component={Routes} />
              {/* <Route path="/" component={() => (window.location = process.env.REACT_APP_NEXTJS_URL)} /> */}
            </Switch>
          </Router>
        </MuiPickersUtilsProvider>
      </Snackbar>
    </DynamicThemeProvider>
  );
};
export default App;
