import React from 'react';
import { useTranslation } from 'react-i18next';
import { Block, FlexRow, Form, PriceBox, SelectOption } from '@guryou/html-components';
import { borderDefinition } from '@guryou/html-theme';
import { useProviderType } from '@guryou/redux';

const VideoFilters = ({ setFilters, triggerFilter, products }) => {
  const { t } = useTranslation();

  const providerType = useProviderType();

  const categories = products
    ? products.map(y => ({ label: y.category?.name || y.svctypesName, value: y.category?.id || y.productsSvctypesId }))
    : [];

  const handleChange = data => {
    if (!data.providerType && !data.category && !data.price) {
      triggerFilter(false);
      setFilters(false);
      return;
    }
    triggerFilter(data);
    setFilters(data);
  };

  return (
    <Block borderBottom={borderDefinition()} xs={10} margin="auto">
      <Form onChange={handleChange}>
        <FlexRow padding={3} justifyContent="center">
          <Block xs={2}>
            <SelectOption name="providerType" label={t('VIDEO_COURSE_PAGE.instructor')} options={providerType} />
          </Block>
          <Block xs={2}>
            <SelectOption name="category" label={t('VIDEO_COURSE_PAGE.category')} options={categories} removeDuplicate={true} />
          </Block>
          <Block xs={2}>
            <PriceBox name="price" label={t('VIDEO_COURSE_PAGE.price')} />
          </Block>
        </FlexRow>
      </Form>
    </Block>
  );
};

export default VideoFilters;
