import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Block, Form, ImageLogo, PasswordBox } from '@guryou/html-components';
import { sizes } from '@guryou/html-theme';
import { resetPassword, stateIsLoaded } from '@guryou/redux';
import Logo from 'assets/img/logo.png';
import ConfirmationSuccess from 'modules/confirmations/ConfirmationSuccess';

const UserConfirmation = () => {
  const { t } = useTranslation();
  const { code } = useParams();
  const dispatch = useDispatch();
  const passwordState = useSelector(state => state.resetPassword);
  const [error, setError] = useState(null);
  const [localState, setLocalState] = useState({});

  useEffect(() => {
    setLocalState(JSON.parse(atob(code)));
  }, [code]);

  if (stateIsLoaded(passwordState)) {
    return <ConfirmationSuccess type="user" />;
  }

  return (
    <Block width={sizes.small} margin="auto" paddingTop={4} textAlign="center">
      <ImageLogo src={Logo} marginRight={5} onClick={() => (window.location = process.env.REACT_APP_NEXTJS_URL)} />
      <Form
        parserVersion={2}
        onSubmit={({ password, repeatPassword }) => {
          if (password !== repeatPassword) {
            setError(t('ACCOUNT.newAndConfirmPassDontMatch'));
            return;
          }
          if (password?.length < 8) {
            setError(t('ACCOUNT.passwordMinimumCharacters'));
            return;
          }
          dispatch(resetPassword({ usersPassword: password, usersContact: localState.email, usersCode: localState.code }));
        }}
      >
        <PasswordBox key={`password${error}`} name="password" label={t('GENERAL.password')} error={error} />
        <PasswordBox name="repeatPassword" label={t('PROFILE.confirmPass')} />
      </Form>
    </Block>
  );
};

export default UserConfirmation;
