import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PricingModel } from '@guryou/core';
import { Block, CheckBox, ContainedButton, DefaultText, FlexRow, Form, Spinner, Title } from '@guryou/html-components';
import { borderDefinition } from '@guryou/html-theme';
import { getPublicSessions, stateIsNotReady } from '@guryou/redux';
import { printDate, printPrice, printTime } from '@guryou/utils';
import { WidgetContext } from './WidgetContext';

const WidgetClassSessions = ({ gyClass }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sessions = useSelector(state => state.widget.sessions);
  const { selectedSessions, setSessionActiveStep, setSelectedSessions } = useContext(WidgetContext);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(gyClass.model === PricingModel.total);

  useEffect(() => {
    dispatch(getPublicSessions(gyClass.id));
  }, [dispatch, gyClass.id]);

  if (stateIsNotReady(sessions)) {
    return <Spinner />;
  }

  if (!sessions.data?.length) {
    return <DefaultText>{t('SESSIONS.noSessionsInClass')}</DefaultText>;
  }

  const onSubmit = data => {
    if (!data.sessions) {
      return;
    }

    if (gyClass.model === PricingModel.total) {
      setSessionActiveStep(s => s + 1);
      setSelectedSessions(sessions.data.map(x => +x.id));
      return;
    }
    const sessionsArray = Array.isArray(data.sessions) ? data.sessions : [data.sessions];
    if (!sessionsArray.length) {
      return;
    }
    setSessionActiveStep(s => s + 1);
    setSelectedSessions(sessionsArray.map(x => +x));
  };

  return (
    <Block margin={0}>
      <Form actions={false} parserVersion={2} onSubmit={onSubmit}>
        <Block maxHeight="11rem" overflowY="auto">
          <FlexRow alignItems="center" justifyContent="space-between" borderBottom={borderDefinition()}>
            <Block xs={6}>
              <CheckBox
                disabled={gyClass.model === PricingModel.total}
                margin="none"
                label={`${t('GENERAL.allSessions')} ${gyClass.model === PricingModel.total ? printPrice(gyClass.price) : ''}`}
                checked={isSelectAllChecked}
                onChange={checked => setIsSelectAllChecked(checked)}
              />
            </Block>
            <DefaultText xs={3}>{t('SERVICES.startTime')}</DefaultText>
            {gyClass.model !== PricingModel.total && <DefaultText xs={3}>{t('GENERAL.price')}</DefaultText>}
          </FlexRow>
          {sessions.data.map(x => {
            return (
              <FlexRow key={x.id} alignItems="center" justifyContent="space-between" borderBottom={borderDefinition()}>
                <Block xs={6}>
                  <CheckBox
                    key={`${x.id}_${isSelectAllChecked || selectedSessions?.includes(+x.id)}`}
                    disabled={gyClass.model === PricingModel.total}
                    name="sessions"
                    value={x.id}
                    margin="none"
                    label={printDate(x.startTime)}
                    checked={isSelectAllChecked || selectedSessions?.includes(x.id)}
                  />
                </Block>
                <Title xs={3} title={printTime(x.startTime)} description={`${x.duration}min`} />
                {gyClass.model !== PricingModel.total && <Title xs={3} title={printPrice(x.price)} description={gyClass.model} />}
              </FlexRow>
            );
          })}
        </Block>
        <ContainedButton text={t('VIDEO_COURSE_PAGE.next')} type="submit" color="primary" />
      </Form>
    </Block>
  );
};

export default WidgetClassSessions;
