import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FlexColumn, Form, ImageMedium, TextBox, TextButton, WarningText } from '@guryou/html-components';
import { forgotPassword } from '@guryou/redux';
import { useTheme } from '@material-ui/core/styles';
import logo from 'assets/img/logo.png';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState(null);
  const forgotPass = useSelector(state => state.forgotPassword);

  useEffect(() => {
    if (forgotPass.errors && forgotPass.errors.status === 400) {
      setErrorMessage(t('NOTIFICATIONS.enterValidEmail'));
    }
  }, [dispatch, t, forgotPass.errors]);

  const handleSubmit = data => {
    dispatch(forgotPassword(data.email));
  };

  return (
    <Form onSubmit={handleSubmit} actions={false}>
      <FlexColumn alignItems="center">
        <ImageMedium marginLeft="auto" marginRight="auto" src={logo} />
        <TextBox name="email" placeholder={t('GENERAL.email')} textrequired="true" email />
        <WarningText style={{ color: theme.palette.primary.main, fontSize: 12 }}>{errorMessage && errorMessage}</WarningText>
        <TextButton marginTop={4} type="submit" text={t('GENERAL.send')} background={theme.palette.primary.main} color="white" width={180} />
      </FlexColumn>
    </Form>
  );
};

export default ForgotPassword;
