import React from 'react';
import { useTranslation } from 'react-i18next';
import { Block, ContainedButton, DefaultText, LargeText } from '@guryou/html-components';
import { useTheme } from '@material-ui/core/styles';
import ConfirmationSubHeader from './ConfirmationSubHeader';

const ConfirmationError = ({ type }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <>
      <ConfirmationSubHeader />
      <Block xs={4} margin="auto" marginTop={4} textAlign="center">
        <LargeText color={theme.palette.error.main}>{t('CONFIRMATIONS.somethingWentWrong')}</LargeText>
        <DefaultText>{t(`CONFIRMATIONS.${type}.error`)}</DefaultText>
        <DefaultText marginBottom={4}>{t('CONFIRMATIONS.pleaseTryAgainLater')}</DefaultText>
        <ContainedButton href={process.env.REACT_APP_NEXTJS_URL} text={t('CONFIRMATIONS.goToFeel')} />
      </Block>
    </>
  );
};

export default ConfirmationError;
