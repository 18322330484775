import _slicedToArray from "/Users/petarbozhinovski/projects/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

var useProviderType = function useProviderType() {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      value = _useState2[0],
      setValue = _useState2[1];

  if (!value) {
    setValue([{
      label: t('GENERAL.centers'),
      value: 'C'
    }, {
      label: t('GENERAL.professionals'),
      value: 'P'
    }]);
  }

  return value;
};

export { useProviderType };