import React from 'react';
import Widget from './Widget';
import { WidgetProvider } from './WidgetContext';

const WidgetContainer = () => (
  <WidgetProvider>
    <Widget />
  </WidgetProvider>
);

export default WidgetContainer;
