import React, { useContext } from 'react';
import { EntityTypes } from '@guryou/core';
import { ExpandMoreIcon, Title } from '@guryou/html-components';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import WidgetClasses from './WidgetClasses';
import { WidgetContext } from './WidgetContext';
import WidgetSubServices from './WidgetSubServices';

const WidgetServices = () => {
  const { activeServiceId, setActiveServiceId, activeTab, services, events, courses } = useContext(WidgetContext);

  const getItems = () => {
    if (activeTab === EntityTypes.service) {
      return services || [];
    }
    if (activeTab === EntityTypes.course) {
      return courses || [];
    }
    return events || [];
  };

  return (
    <>
      {getItems().map(service => (
        <Accordion
          square={true}
          key={service.id}
          onChange={(e, isExpanded) => {
            if (e.target.tagName.toLocaleLowerCase() === 'span') {
              return false;
            }
            setActiveServiceId(isExpanded ? service.id : null);
          }}
          expanded={activeServiceId === service.id}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Title title={service.name} margin={1} />
          </AccordionSummary>
          <AccordionDetails>
            {activeTab === EntityTypes.service && activeServiceId === service.id && <WidgetSubServices />}
            {[EntityTypes.event, EntityTypes.course].includes(activeTab) && activeServiceId === service.id && <WidgetClasses />}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default WidgetServices;
