import { classes } from './classes';
import { sections } from './sections';
import { topLevelServices } from './topLevelServices';
import { videos } from './videos';
import { videoUpload } from './videoUpload';
import { RESERVATIONS_GET_CURRENT } from '../actions/calendarActions';
import { basicReducer } from '../core';
import { ALL_NOT_ASSIGNED_PERSONNELS, ALL_RESOURCE_UPCOMING_EVENTS, COLLABORATORS_GET_MANY, CREATE_SESSION, DELETE_SESSION, PERSONNEL_ARCHIVE, PERSONNEL_CREATE, PERSONNEL_DATE_SLOTS_GET_MANY, PERSONNEL_EDIT, PERSONNEL_GET_MANY, PERSONNEL_HOLIDAY_CREATE, PERSONNEL_HOLIDAY_DELETE, PERSONNEL_HOLIDAY_UPDATE, PERSONNEL_HOLIDAYS_GET_MANY, PERSONNEL_SCHEDULE_GET_MANY, PERSONNEL_TIME_SLOTS_GET_MANY, PHYSICAL_PERSONNEL_GET_MANY, PROVIDER_ADD_CLASS, PROVIDER_ARCHIVE_SERVICE, PROVIDER_ARCHIVED_SERVICES_GET_MANY, PROVIDER_CITY_GET, PROVIDER_CLASS_DELETE, PROVIDER_COURSES_GET_MANY, PROVIDER_EVENTS_GET_MANY, PROVIDER_GET_CLASSES, PROVIDER_GET_GENERAL_INFO, PROVIDER_IMAGES_CREATE, PROVIDER_IMAGES_DELETE, PROVIDER_IMAGES_GET, PROVIDER_SERVICE_ACTIVE_STATUS, PROVIDER_SERVICES_GET_MANY, PROVIDER_SESSIONS_GET_MANY, PROVIDER_SUB_SERVICE_ACTIVE_STATUS, PROVIDER_UPDATE, SESSION_UPDATE, SUBSCRIPTIONS_GET_MANY } from '../new';
import { ARCHIVED_PRODUCTS, CHECK_COUPON, CLASS_ACTIVE_STATUS, CREATE_CUSTOMER_BILLING_INFO_HANDLE, CUSTOMER_BOOKING_EVENTS_GET_MANY, CUSTOMERS_GET_MANY, GET_PLANS, HOLIDAYS_GET_MANY, OWNER_PROVIDER_CHECK_STRIPE_CUSTOMER_HANDLE, PRODUCT_ARCHIVE, PRODUCT_CREATE, PRODUCT_FILE_PREVIEW, PRODUCT_GROUP_CREATE, PRODUCT_GROUP_DELETE, PRODUCT_GROUP_UPDATE, PRODUCT_GROUPS_GET, PRODUCT_S3, PRODUCT_SECTION_CREATE, PRODUCT_SECTION_DELETE, PRODUCT_SECTION_UPDATE, PRODUCT_STATUS_UPDATE, PRODUCT_UPDATE, PRODUCT_VIDEO_CREATE, PRODUCT_VIDEO_DELETE, PRODUCT_VIDEO_UPDATE, PRODUCT_VIDEOS_GET_MANY, PRODUCTS_GET_MANY, PRODUCTS_SECTIONS_GET_MANY, PROVIDER_ADD_BREAKS, PROVIDER_ADD_GROUP, PROVIDER_ADD_SCHEDULE, PROVIDER_CHECK_STRIPE_CUSTOMER_HANDLE, PROVIDER_COURSES_GET_MANY_GROUP, PROVIDER_DELETE_BREAKS, PROVIDER_GET_BREAKS, PROVIDER_GET_CATEGORIES, PROVIDER_GET_EVENT_GROUPS, PROVIDER_GET_GROUPS, PROVIDER_GET_ONETIME, PROVIDER_GET_ONETIME_GROUP, PROVIDER_GET_RESOURCES, PROVIDER_GET_SCHEDULE, PROVIDER_SUBSCRIPTION_GET, PURCHASES_GET_MANY, RESTORE_PRODUCT, SECTION_VIDEOS_GET, SEND_CONFIRM_SUB_B2B_HANDLE, SEND_PAYMENT_METHOD_TOKEN_TO_BACKEND_HANDLE, SEND_STRIPE_AUTH_CODE, SEND_STRIPE_FROM_OTHER_PROVIDER_HANDLE, SEND_SUBSCRIBE_BACKEND_HANDLE, WEB_INTEGRATIONS_CREATE, WEB_INTEGRATIONS_DELETE, WEB_INTEGRATIONS_GET_MANY } from '../types';
var provider = {
  addBreak: basicReducer(PROVIDER_ADD_BREAKS),
  addProviderImage: basicReducer(PROVIDER_IMAGES_CREATE),
  addSchedule: basicReducer(PROVIDER_ADD_SCHEDULE),
  allServices: basicReducer(PROVIDER_SERVICES_GET_MANY),
  archiveProduct: basicReducer(PRODUCT_ARCHIVE),
  archivedProducts: basicReducer(ARCHIVED_PRODUCTS),
  archivedServices: basicReducer(PROVIDER_ARCHIVED_SERVICES_GET_MANY),
  breaks: basicReducer(PROVIDER_GET_BREAKS),
  categories: basicReducer(PROVIDER_GET_CATEGORIES),
  checkOwnerCustomerStripe: basicReducer(OWNER_PROVIDER_CHECK_STRIPE_CUSTOMER_HANDLE),
  checkProviderCustomerStripe: basicReducer(PROVIDER_CHECK_STRIPE_CUSTOMER_HANDLE),
  classStatus: basicReducer(CLASS_ACTIVE_STATUS),
  classes: classes(PROVIDER_GET_CLASSES),
  collaborators: basicReducer(COLLABORATORS_GET_MANY),
  confirmB2bSuccess: basicReducer(SEND_CONFIRM_SUB_B2B_HANDLE),
  courses: topLevelServices(PROVIDER_COURSES_GET_MANY),
  createClass: basicReducer(PROVIDER_ADD_CLASS),
  createGroup: basicReducer(PROVIDER_ADD_GROUP),
  createGroupForProducts: basicReducer(PRODUCT_GROUP_CREATE),
  createProduct: basicReducer(PRODUCT_CREATE),
  createProductSection: basicReducer(PRODUCT_SECTION_CREATE),
  createProductVideo: videoUpload(PRODUCT_VIDEO_CREATE),
  currentReservations: basicReducer(RESERVATIONS_GET_CURRENT),
  customerBookings: basicReducer(CUSTOMER_BOOKING_EVENTS_GET_MANY),
  customers: basicReducer(CUSTOMERS_GET_MANY),
  deleteBreak: basicReducer(PROVIDER_DELETE_BREAKS),
  deleteClass: basicReducer(PROVIDER_CLASS_DELETE),
  deleteGroupForProducts: basicReducer(PRODUCT_GROUP_DELETE),
  deleteProviderImage: basicReducer(PROVIDER_IMAGES_DELETE),
  deleteSection: basicReducer(PRODUCT_SECTION_DELETE),
  deleteService: basicReducer(PROVIDER_ARCHIVE_SERVICE),
  deleteVideo: basicReducer(PRODUCT_VIDEO_DELETE),
  deletedSession: basicReducer(DELETE_SESSION),
  editGroupForProducts: basicReducer(PRODUCT_GROUP_UPDATE),
  editProduct: basicReducer(PRODUCT_UPDATE),
  editSection: basicReducer(PRODUCT_SECTION_UPDATE),
  editVideo: basicReducer(PRODUCT_VIDEO_UPDATE),
  eventGroups: basicReducer(PROVIDER_GET_EVENT_GROUPS),
  events: topLevelServices(PROVIDER_EVENTS_GET_MANY),
  groupCourses: basicReducer(PROVIDER_COURSES_GET_MANY_GROUP),
  groups: basicReducer(PRODUCT_GROUPS_GET),
  holidays: basicReducer(HOLIDAYS_GET_MANY),
  oneTime: topLevelServices(PROVIDER_GET_ONETIME),
  oneTimeGroup: basicReducer(PROVIDER_GET_ONETIME_GROUP),
  paymentMethod: basicReducer(SEND_PAYMENT_METHOD_TOKEN_TO_BACKEND_HANDLE),
  personnel: basicReducer(PERSONNEL_GET_MANY),
  physicalPersonnel: basicReducer(PHYSICAL_PERSONNEL_GET_MANY),
  allNotAssignedPersonnel: basicReducer(ALL_NOT_ASSIGNED_PERSONNELS),
  allUpcomingEventsPersonnel: basicReducer(ALL_RESOURCE_UPCOMING_EVENTS),
  personnelCreate: basicReducer(PERSONNEL_CREATE),
  personnelEdit: basicReducer(PERSONNEL_EDIT),
  personnelArchive: basicReducer(PERSONNEL_ARCHIVE),
  personnelHolidays: basicReducer(PERSONNEL_HOLIDAYS_GET_MANY),
  personnelHolidaysCreate: basicReducer(PERSONNEL_HOLIDAY_CREATE),
  personnelHolidaysEdit: basicReducer(PERSONNEL_HOLIDAY_UPDATE),
  personnelHolidaysDelete: basicReducer(PERSONNEL_HOLIDAY_DELETE),
  personnelSchedule: basicReducer(PERSONNEL_SCHEDULE_GET_MANY),
  personnelTimeSlots: basicReducer(PERSONNEL_TIME_SLOTS_GET_MANY, []),
  personnelDateSlots: basicReducer(PERSONNEL_DATE_SLOTS_GET_MANY, []),
  plans: basicReducer(GET_PLANS),
  previewFile: basicReducer(PRODUCT_FILE_PREVIEW),
  productStatus: basicReducer(PRODUCT_STATUS_UPDATE),
  products: topLevelServices(PRODUCTS_GET_MANY),
  providerCity: basicReducer(PROVIDER_CITY_GET),
  // providerDetails: basicReducer(PROVIDER_GET),
  generalInfo: basicReducer(PROVIDER_GET_GENERAL_INFO),
  providerImages: basicReducer(PROVIDER_IMAGES_GET),
  providerSubscription: basicReducer(PROVIDER_SUBSCRIPTION_GET),
  purcheses: basicReducer(PURCHASES_GET_MANY),
  // reservationCancel: basicReducer(RESERVATIONS_CANCEL),
  // reservationCreate: basicReducer(RESERVATIONS_CREATE),
  // reservationUpdate: basicReducer(RESERVATIONS_UPDATE),
  resources: basicReducer(PROVIDER_GET_RESOURCES),
  restoredProduct: basicReducer(RESTORE_PRODUCT),
  schedule: basicReducer(PROVIDER_GET_SCHEDULE),
  sections: sections(PRODUCTS_SECTIONS_GET_MANY),
  sendSubscribeInfo: basicReducer(SEND_SUBSCRIBE_BACKEND_HANDLE),
  serviceGroups: basicReducer(PROVIDER_GET_GROUPS),
  serviceStatus: basicReducer(PROVIDER_SERVICE_ACTIVE_STATUS),
  sessionCreated: basicReducer(CREATE_SESSION),
  sessionUpdated: basicReducer(SESSION_UPDATE),
  sessions: basicReducer(PROVIDER_SESSIONS_GET_MANY),
  stripeAccountFromOtherProvider: basicReducer(SEND_STRIPE_FROM_OTHER_PROVIDER_HANDLE),
  stripeConnect: basicReducer(SEND_STRIPE_AUTH_CODE),
  stripeCoupon: basicReducer(CHECK_COUPON),
  stripeCustomerAccountCreated: basicReducer(CREATE_CUSTOMER_BILLING_INFO_HANDLE),
  // subscriptionCancel: basicReducer(SUBSCRIPTIONS_CANCEL),
  // subscriptionCreate: basicReducer(SUBSCRIPTIONS_CREATE),
  subscriptions: basicReducer(SUBSCRIPTIONS_GET_MANY),
  // updateClass: basicReducer(PROVIDER_UPDATE_CLASS),
  updateProviderDetails: basicReducer(PROVIDER_UPDATE),
  // updateService: basicReducer(PROVIDER_UPDATE_SERVICE),
  uploadToS3: basicReducer(PRODUCT_S3),
  videos: videos(SECTION_VIDEOS_GET),
  videosOnProduct: basicReducer(PRODUCT_VIDEOS_GET_MANY),
  webIntegrations: basicReducer(WEB_INTEGRATIONS_GET_MANY),
  webIntegrationsCreate: basicReducer(WEB_INTEGRATIONS_CREATE),
  webIntegrationsDelete: basicReducer(WEB_INTEGRATIONS_DELETE),
  // createService: basicReducer(PROVIDER_ADD_SERVICE),
  // createSubservice: basicReducer(PROVIDER_ADD_SUB_SERVICE),
  // deleteSubservice: basicReducer(PROVIDER_SUB_SERVICE_DELETE),
  // editSubservice: basicReducer(PROVIDER_SUB_SERVICE_UPDATE),
  subserviceStatus: basicReducer(PROVIDER_SUB_SERVICE_ACTIVE_STATUS) // subservices: subServices(PROVIDER_SUB_SERVICES_GET_MANY),

};
export { provider };