import React from 'react';
import BookSection from './bookSection';
import CTASection from './ctaSection';
import HeroSection from './heroSection';
import ReadSection from './readSection';
import SelectSection from './selectSection';

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <BookSection />
      <ReadSection />
      <SelectSection />
      <CTASection />
    </>
  );
};
export default HomePage;
