import React from 'react';
import { Block, FlexColumn, FlexRow, ImageMedium, MediumText, RouterLink } from '@guryou/html-components';
import { borderDefinition, largeBorderRadius } from '@guryou/html-theme';
import { boxShadow } from '@guryou/html-theme/src/constants';
import { useTheme } from '@material-ui/core/styles';

const CenterCard = ({ description, image, header, footer }) => {
  const theme = useTheme();
  return (
    <FlexColumn xs={4} margin={10} border={borderDefinition()} borderRadius={largeBorderRadius} boxShadow={boxShadow} minWidth={200} maxWidth={300}>
      <RouterLink to="#">
        <FlexRow margin={0} justifyContent="space-between" background={theme.palette.primary.main} padding={4}>
          <MediumText fontSize="1rem" fontWeight={500} color={theme.palette.primary.contrastText}>
            {header}
          </MediumText>
        </FlexRow>
        <ImageMedium src={image} margin={0} xs={12} height={150} />
        <Block
          height={200}
          maxHeight={200}
          overflowY="scroll"
          padding={4}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        ></Block>
        <FlexRow justifyContent="flex-end" padding={4} borderTop={borderDefinition()}>
          {footer}
        </FlexRow>
      </RouterLink>
    </FlexColumn>
  );
};

export default CenterCard;
