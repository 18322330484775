import React from 'react';
import SelectCard from './SelectCard';
import { SelectCardInformation } from './SelectCardInformation';

const SelectSectionCollection = () => {
  return (
    <>
      {SelectCardInformation.map(card => (
        <SelectCard
          description1={card.description1}
          description2={card.description2}
          description3={card.description3}
          image={card.image}
          title={card.title}
          key={card.id}
        ></SelectCard>
      ))}
    </>
  );
};

export default SelectSectionCollection;
