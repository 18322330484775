import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FlexRow, IconButton, LogOutIcon, MoreIcon, ProfileIcon, stringToColor } from '@guryou/html-components';
import { LOGOUT } from '@guryou/redux';
import { getInitials } from '@guryou/utils';
import { Menu as MuiMenu, MenuItem as MuiMenuItem } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

const ProfileMenu = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const { username } = useSelector(state => state.auth.data);
  const localInitials = username && getInitials(username);
  const userColour = username && stringToColor(username);
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const { t } = useTranslation();

  return (
    <FlexRow xs={5} alignItems="center">
      <Avatar style={{ backgroundColor: userColour }}>{localInitials}</Avatar>
      <IconButton label="Settings" onClick={handleClick}>
        <MoreIcon />
      </IconButton>
      <MuiMenu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MuiMenuItem
          onClick={() => {
            history.push('/profile');
            handleClose();
          }}
        >
          <ProfileIcon />
          <span>{t('PROFILE.title')}</span>
        </MuiMenuItem>
        <MuiMenuItem
          onClick={() => {
            dispatch({ type: LOGOUT });
            handleClose();
          }}
        >
          <LogOutIcon />
          <span>{t('PROFILE.logout')}</span>
        </MuiMenuItem>
      </MuiMenu>
    </FlexRow>
  );
};

export default ProfileMenu;
