import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FlexRow, Modal, openModal, OutlinedButton, resetModal } from '@guryou/html-components';
import ChangePasswordForm from './ChangePasswordForm';
// import ChangePassword from '../components/ChangePassword';

const ChangePassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const accountUpdatePassword = useSelector(state => state.accountUpdatePassword);

  useEffect(() => {
    dispatch(resetModal());
  }, [dispatch, accountUpdatePassword.version]);

  return (
    <FlexRow justifyContent="flex-end" marginRight={10}>
      <Modal id="changePassword" maxWidth="sm" title={t('PROVIDERS.changePassword')}>
        <ChangePasswordForm />
      </Modal>
      <OutlinedButton text={t('PROVIDERS.changePassword')} onClick={() => dispatch(openModal('changePassword'))} />
    </FlexRow>
  );
};

export default ChangePassword;
