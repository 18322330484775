import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlexColumn, FlexRow, SmallText } from '@guryou/html-components';
import { printDateTime } from '@guryou/utils';

const Sessions = ({ service }) => {
  const { t } = useTranslation();
  return (
    <FlexRow padding={1} xs={10} marginLeft="auto" marginRight="auto" alignItems="center">
      {service && service.sessions
        ? service.sessions.map((x, i) => (
            <FlexColumn key={i + Date.now()} xs={12} marginTop={1} marginBottom={1}>
              <SmallText xs={7} color="gray">
                {`${t('GENERAL.from')} ${printDateTime(x.startTime)} ${t('GENERAL.to')} ${printDateTime(x.endTime)}`}
              </SmallText>
              <SmallText>{x.resourceName}</SmallText>
            </FlexColumn>
          ))
        : '/'}
    </FlexRow>
  );
};

export default Sessions;
