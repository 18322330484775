import _slicedToArray from "/Users/petarbozhinovski/projects/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityTypes } from '@guryou/core';

var useEntityTypes = function useEntityTypes() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    showAll: false,
    showVideos: true,
    showWorkouts: false,
    showMemberships: false
  };

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      values = _useState2[0],
      setValues = _useState2[1];

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  if (!values) {
    var val = [];
    options.showAll && val.push({
      value: 'all',
      label: t('GENERAL.all')
    });
    val.push({
      value: EntityTypes.service,
      label: t('GENERAL.services')
    });
    val.push({
      value: EntityTypes.course,
      label: t('GENERAL.courses')
    });
    val.push({
      value: EntityTypes.event,
      label: t('GENERAL.events')
    });
    options.showVideos && val.push({
      value: EntityTypes.video,
      label: t('GENERAL.videos')
    }); // options.showWorkouts && val.push({ value: EntityTypes.workout, label: t('GENERAL.workout') });

    options.showMemberships && val.push({
      value: EntityTypes.membership,
      label: t('GENERAL.membership')
    });
    setValues(val);
  }

  return values;
};

export { useEntityTypes };