import _objectSpread from "/Users/petarbozhinovski/projects/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
var colors = {
  // OBSOLETE
  dark1: '#0E1F28',
  light1: '#FAFBF8',
  lightgrey: '#E8E8E8',
  darkgrey: '#BEBEBE',
  grey: '#757575',
  // OBSOLETE
  amaranth: '#E53C4C',
  black: '#121212',
  blackAlpha: '#0000008A',
  blackAlpha2: '#0000005A',
  boulder: '#777777',
  brownMineShaft: '#313131',
  cabaret: '#D94D61',
  conifer: '#88D54F',
  ebonyClay: '#202F38',
  ebonyClay2: '#222A3E',
  firefly: '#0E1F28',
  fruitSalad: '#469B56',
  fruitSalad2: '#42954A',
  gray: '#8D8D8D',
  jaggedIce: '#B8E6D6',
  jungleGreen: '#24A676',
  lightningYellow: '#FEBA12',
  malachite: '#2BE35C',
  bayOfMany: '#2E3191',
  malibu: '#87BBFD',
  mandy: '#E6616A',
  mandy2: '#E9616A',
  terracotta: '#E67461',
  mercury: '#EAEAEA',
  milanoRed: '#C52100',
  mineShaft: '#252525',
  nileBlue: '#172B4D',
  onahau: '#C4F0FF',
  orient: '#005B8C',
  osloGray: '#898B8D',
  persimmon: '#FF5D52',
  pink: '#FDBDC9',
  carouselPink: '#FBE6E8',
  pomegranate: '#F44336',
  red: '#DF0101',
  scarlet: '#FF3100',
  springWood: '#FAFBF8',
  stormGray: '#73747A',
  white: '#FFFFFF',
  lightGrey1: '#EEEEEE',
  limeGreen: '#32CD32',
  portage: '#808DEC',
  purpleHeart: '#801AD8',
  spray: '#70EBE5' //rgb(37, 37, 47);
  //rgb(230, 97, 106)

};
var defaultPalette = {
  type: 'light',
  contrastThreshold: 3,
  tonalOffset: 0.2,
  success: {
    main: '#469B56',
    light: '#24A676',
    dark: '#42954A',
    contrastText: '#FAFBF8'
  },
  error: {
    main: '#E53C4C',
    light: '#FF5D52',
    dark: '#DF0101',
    contrastText: '#FAFBF8'
  },
  info: {
    main: '#5567E6',
    light: '#87bbfd',
    dark: '#2E3191',
    contrastText: '#FAFBF8'
  },
  warning: {
    main: '#FFAB00',
    light: '#FEBA12',
    dark: '#F2A405',
    contrastText: '#FAFBF8'
  },
  background: {
    default: '#FAFBF8',
    lightGrey: '#d8dce2',
    white: '#FFFFFF'
  },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161'
  }
};
var pink = {
  main: '#E6616A',
  light: '#E67461',
  dark: '#D94D61',
  contrastText: '#FAFBF8'
};
var black = {
  main: '#252525',
  light: '#313131',
  dark: '#121212',
  contrastText: '#FAFBF8'
};

var feelThemePalette = _objectSpread(_objectSpread({}, defaultPalette), {}, {
  primary: pink,
  secondary: black
});

var dealThemePalette = _objectSpread(_objectSpread({}, defaultPalette), {}, {
  primary: black,
  secondary: pink
});

var feelMobilePalette = {
  light: {
    backgroundColor: '#fff',
    main: '#E6626A',
    accent: '#252525',
    text: '#000',
    lightText: '#fff'
  },
  dark: {}
};
var dealMobilePalette = {
  light: {
    backgroundColor: '#fff',
    main: '#252525',
    accent: '#E6626A',
    text: '#000',
    lightText: '#fff',
    accentText: '#252525',
    disabledColor: '#808080'
  },
  dark: {}
};
var trainMobilePalette = {
  light: {
    backgroundColor: '#fff',
    main: '#469B56',
    accent: '#252525',
    text: '#000',
    lightText: '#fff',
    accentText: '#252525',
    warmup: '#E53C4C',
    cooldown: '#5567E6',
    defaultGrey: '#707070'
  },
  dark: {}
};
export { colors, defaultPalette, dealThemePalette, feelThemePalette, feelMobilePalette, dealMobilePalette, trainMobilePalette };