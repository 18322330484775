import React from 'react';
import { Block, FlexColumn, FlexRow, LargeText, SmallGrayText } from '@guryou/html-components';
import { boxShadow, extraLargeBorderRadius } from '@guryou/html-theme/src/constants';
import { information } from './MemberCardInformation';

const MemberCardCollection = () => {
  return (
    <FlexRow justifyContent="space-around" margin={0} xs={11} marginLeft="auto" marginRight="auto">
      {information.map(card => (
        <FlexColumn margin={5} key={card.id}>
          <Block
            margin={4}
            width={150}
            height={150}
            borderRadius={extraLargeBorderRadius}
            backgroundImage={`url('${card.image}')`}
            backgroundSize={!card.image.includes('http') ? '70% 70%' : 'cover'}
            backgroundRepeat="no-repeat"
            backgroundPosition="center center"
            boxShadow={boxShadow}
          />
          <LargeText>{card.name}</LargeText>
          <SmallGrayText>{card.job}</SmallGrayText>
        </FlexColumn>
      ))}
    </FlexRow>
  );
};

export default MemberCardCollection;
