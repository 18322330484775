import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Avatar, Block, FlexColumn, generateImageUrl, LargeText, Modal, openModal, SmallGrayText } from '@guryou/html-components';
import UserImageForm from './UserImageForm';

const UserImage = ({ acc }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <>
      <Block xs={12}>
        <FlexColumn
          justifyContent="center"
          textAlign="center"
          marginBottom={2}
          width="fit-content"
          onClick={() => dispatch(openModal('changeAvatar'))}
        >
          <Avatar border={true} size="large" imageSrc={generateImageUrl(acc.image)} imageAlt="user-profile-photo" />
          <Block>
            <LargeText>{acc.name}</LargeText>
            <SmallGrayText>{acc.role}</SmallGrayText>
          </Block>
        </FlexColumn>
      </Block>
      <Modal id="changeAvatar" title={t('GENERAL.changeAvatar')}>
        <UserImageForm />
      </Modal>
    </>
  );
};

export default UserImage;
