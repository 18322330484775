import React from 'react';
import { FileTypes } from '@guryou/core';
import { DefaultText, ExpandMoreIcon, FlexRow, ImgIcon, PdfIcon, PlayIcon } from '@guryou/html-components';
import { borderDefinition } from '@guryou/html-theme';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

const SectionDetails = ({ section, activeSection, setActiveSection, files }) => {
  const allFiles = files.filter(x => x.sectionId === activeSection);
  return (
    <Accordion
      square={true}
      onChange={(e, isExpanded) => {
        if (e.target.tagName.toLocaleLowerCase() === 'span') {
          return false;
        }
        setActiveSection(isExpanded ? section.id : null);
      }}
      expanded={activeSection === section.id}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <DefaultText padding={2} fontWeight={700}>
          {section.name}
        </DefaultText>
      </AccordionSummary>
      {activeSection === section.id && (
        <AccordionDetails>
          {allFiles.map((x, i) => (
            <FlexRow
              borderTop={borderDefinition()}
              margin={0}
              padding={3}
              paddingLeft={8}
              paddingRight={8}
              marginLeft="auto"
              marginRight="auto"
              justifyContent="space-between"
            >
              <DefaultText key={`file_${i}`}>{x.title}</DefaultText>
              {x.type === FileTypes.document ? <PdfIcon /> : x.type === FileTypes.image ? <ImgIcon /> : <PlayIcon />}
            </FlexRow>
          ))}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default SectionDetails;
