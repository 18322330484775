export const information = [
  {
    id: '1',
    description: `Postura, eleganza, armonia, ma anche fiducia in sé stessi e capacità di relazionarsi con gli altri. La danza è questo, e molto altro ancora. A raccontarlo è un libro, Il Movimento della danza, e la sua autrice. Com’è nata l’idea di un libro? Fa parte del mio percorso di vita, quello di un’ex atleta, ballerina, …"`,
    image: `https://wallpaperaccess.com/full/414469.jpg`,
    header: 'HEALTH',
    footer: 'Lorem',
  },
  {
    id: '2',
    description: `La criochirurgia è un trattamento terapeutico ambulatoriale molto efficace, che viene effettuato per rimuovere alcune lesioni della pelle mediante l’utilizzo dell’azoto liquido a temperature estremamente basse (-195°C). L’azoto è uno dei gas che compongono l’atmosfera, è incolore e non infiammabile ed evapora rapidamente a contatto con l’aria, in quanto assorbe enormi quantità di calore. A…`,
    image: `https://wallpaperaccess.com/full/414469.jpg`,
    header: 'HEALTH',
    footer: 'Lorem',
  },
  {
    id: '3',
    description: `Corregge la postura, dona armonia e fluidità ai movimenti, rafforza i muscoli, ma non solo. Stefano Garulli, chinesiologo e insegnante del Pilates Fitness Studio di Parma ci svela segreti e benefici di questa disciplina. “Vivi il tuo corpo e libera la mente” è questa la frase che hai scelto per presentare il tuo studio di …`,
    image: `https://wallpaperaccess.com/full/414469.jpg`,
    header: 'MOVES',
    footer: 'Lorem',
  },
  {
    id: '4',
    description: `Una sostanza che dà un contributo fondamentale alla salute di ossa e denti, alle funzioni del sistema immunitario e muscolare, al mantenimento dei livelli di calcio nel sangue e al normale assorbimento del calcio e del fosforo. La vitamina D viene prodotta dal corpo grazie all’esposizione al sole, mentre la dieta ci fornisce solo il …`,
    image: `https://wallpaperaccess.com/full/414469.jpg`,
    header: 'MINDSET',
    footer: 'Lorem',
  },
];
