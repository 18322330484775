import { basicReducer } from '../core';
import { CALENDAR_EVENTS, CALENDAR_FILTER_GET_CLASSES, CALENDAR_FILTER_GET_COURSES, CALENDAR_FILTER_GET_EVENTS, CALENDAR_FILTER_GET_SERVICES, CALENDAR_FILTER_GET_SUBSERVICES } from '../types';
var calendar = {
  calendarEvents: basicReducer(CALENDAR_EVENTS),
  services: basicReducer(CALENDAR_FILTER_GET_SERVICES),
  courses: basicReducer(CALENDAR_FILTER_GET_COURSES),
  events: basicReducer(CALENDAR_FILTER_GET_EVENTS),
  subServices: basicReducer(CALENDAR_FILTER_GET_SUBSERVICES),
  classes: basicReducer(CALENDAR_FILTER_GET_CLASSES)
};
export { calendar };