import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Block, ContainedButton, ExpandMoreIcon, Title } from '@guryou/html-components';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import WidgetClassSessions from './WidgetClassSessions';
import { WidgetContext } from './WidgetContext';
import WidgetSubscription from './WidgetSubscription';

const WidgetClassItem = ({ gyClassId }) => {
  const { t } = useTranslation();
  const { sessionActiveStep, activeClassId, setActiveClassId, setSessionActiveStep, setSelectedSessions } = useContext(WidgetContext);
  const classes = useSelector(state => state.widget.classes);
  const gyClass = classes.data?.find(x => x.id === +gyClassId);

  if (!gyClass) {
    return null;
  }

  return (
    <Accordion
      square={true}
      onChange={(_, isExpanded) => {
        setActiveClassId(isExpanded ? +gyClass.id : null);
        setSelectedSessions([]);
        setSessionActiveStep(0);
      }}
      expanded={activeClassId === +gyClass.id}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Title title={gyClass.name} xs={8} margin={1} />
        {activeClassId !== +gyClass.id && <ContainedButton text={t('NEWSLETTER.subscribe')} margin={1} xs={12} md={4} color="primary" />}
      </AccordionSummary>

      {activeClassId === +gyClass.id && (
        <AccordionDetails>
          <Block margin={2}>
            <Stepper activeStep={sessionActiveStep}>
              <Step completed={false} active={sessionActiveStep === 0}>
                <StepLabel>{t('BOOK_WIDGET.select')}</StepLabel>
              </Step>
              <Step completed={true} active={sessionActiveStep === 0}>
                <StepLabel>{t('USERS.confirm')}</StepLabel>
              </Step>
            </Stepper>
            {sessionActiveStep === 0 && <WidgetClassSessions gyClass={gyClass} />}
            {sessionActiveStep === 1 && <WidgetSubscription gyClass={gyClass} />}
          </Block>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default WidgetClassItem;
