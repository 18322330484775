import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@guryou/colors';
import { Block, FlexColumn, FlexRow, LargeText, MediumText, OutlinedButton, RouterLink, SmallText } from '@guryou/html-components';
import { today } from '@guryou/html-components/src/calendar/dates';
import { mediumBorderRadius } from '@guryou/html-theme';
import { useTheme } from '@material-ui/core/styles';
import { information } from './HeroInformation';

const HeroSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const date = today();
  const { title, name, description, image } = information;

  return (
    <Block
      margin={0}
      xs={12}
      height={650}
      position="relative"
      textAlign="center"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundImage={`url('${image}')`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center center"
    >
      <FlexColumn>
        <FlexColumn background="rgba(0, 0, 0, 0.18)" xs={6} padding={5} margin={0} color={colors.white} textAlign="center">
          <Block
            display="flex"
            position="absolute"
            justifyContent="center"
            marginTop="-48px"
            left="65.8%"
            width={170}
            height={50}
            borderTopRightRadius={mediumBorderRadius}
            alignItems="center"
            backgroundColor={theme.palette.primary.main}
          >
            <MediumText fontSize="1rem" fontWeight={400} color={colors.white}>
              HEALTH
            </MediumText>
          </Block>
          <LargeText fontSize="2rem" fontWeight={600} color={colors.white} marginBottom={5}>
            {title}
          </LargeText>
          <MediumText fontSize="1.4rem" fontWeight={400} color={colors.white} marginBottom={5}>
            {description}
          </MediumText>
          <OutlinedButton text="Continua a leggere" color={colors.white} border-color={colors.white} borderWidth="2px"></OutlinedButton>
        </FlexColumn>
        <FlexRow
          justifyContent="space-between"
          padding={0}
          paddingLeft={5}
          paddingRight={5}
          height={35}
          backgroundColor={colors.white}
          color={colors.white}
          margin={0}
          xs={6}
          borderBottomLeftRadius={mediumBorderRadius}
          borderBottomRightRadius={mediumBorderRadius}
          textAlign="center"
          alignItems="center"
        >
          <SmallText fontSize="0.6rem">{`${t('PROFILE.date')}:${date}`}</SmallText>
          <RouterLink to="#">
            <SmallText fontSize="0.6rem" textDecoration="underline" fontStyle="italic">
              {name}
            </SmallText>
          </RouterLink>
        </FlexRow>
      </FlexColumn>
    </Block>
  );
};

export default HeroSection;
