import React from 'react';
import { FlexRow } from '@guryou/html-components';
import ArticleCard from './ArticleCard';
import { information } from './ArticleCardInformation';
const ArticleCardCollection = () => {
  return (
    <FlexRow justifyContent="space-between" margin={0} xs={12} marginLeft="auto" marginRight="auto">
      {information.map(card => (
        <ArticleCard key={card.id} description={card.description} image={card.image} header={card.header} footer={card.footer} />
      ))}
    </FlexRow>
  );
};

export default ArticleCardCollection;
