import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  EmptyPage,
  Filters,
  LargeText,
  MultipleItemsContainer,
  SelectOption,
  Spinner,
  TableCellBody,
  TableRowBody,
  TextBox,
} from '@guryou/html-components';
import { getMyOrders, stateIsLoaded, stateIsNotInitialized, useEntityTypes } from '@guryou/redux';
import { parseAsBool, printPrice } from '@guryou/utils';

const PaymentHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myOrders = useSelector(state => state.myOrders);

  const ordersRef = useRef();

  const entityTypeOptions = useEntityTypes({ showAll: true });

  useEffect(() => {
    if (stateIsNotInitialized(myOrders)) {
      dispatch(getMyOrders());
    }
  }, [dispatch, myOrders]);

  if (!stateIsLoaded(myOrders)) {
    return <Spinner />;
  }

  const handleChange = (data, items) => {
    let result = items;
    if (data.type) {
      result = result.filter(x => x.type === data.type);
    }
    if (data.search) {
      result = result.filter(x => x.name && x.name.toLowerCase().startsWith(data.search.toLowerCase()));
    }
    return result;
  };

  return (
    <MultipleItemsContainer
      {...myOrders}
      ref={ordersRef}
      key="PaymentHistory"
      title={t('PROFILE.paymentHistory')}
      showDownloadTool={true}
      titleAndFilters={true}
      downloadFilename={`GURYOU_${t('PROFILE.paymentHistory')}.csv`}
    >
      <Filters onChange={handleChange}>
        <TextBox label={t('GENERAL.search')} name="search" margin="none" placeholder={t('PROFILE.searchByItem')} />
        <SelectOption label={t('PROFILE.type')} name="type" margin="none" options={entityTypeOptions} />
      </Filters>
      <EmptyPage>
        <LargeText>{t('GENERAL.noPaymentsHistory')}</LargeText>
      </EmptyPage>
      <TableRowBody>
        <TableCellBody name="name" label={t('PROFILE.item')} />
        <TableCellBody name="date" label={t('PROFILE.date')} />
        <TableCellBody name="paid" label={t('PROFILE.status')} render={value => (parseAsBool(value) ? t('PROFILE.paid') : t('PROFILE.unpaid'))} />
        <TableCellBody name="price" label={t('PROFILE.price')} render={value => printPrice(value)} />
        <TableCellBody name="type" label={t('PROFILE.type')} render={value => value && value.charAt(0).toUpperCase() + value.slice(1)} />
      </TableRowBody>
    </MultipleItemsContainer>
  );
};

export default PaymentHistory;
