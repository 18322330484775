import React from 'react';
import { FlexRow } from '@guryou/html-components';
import CenterCard from './CenterCard';
import { information } from './CenterCardInformation';
const CenterCardCollection = () => {
  return (
    <FlexRow justifyContent="space-between" margin={0}>
      {information.map(card => (
        <CenterCard key={card.id} description={card.description} image={card.image} header={card.header} footer={card.footer} />
      ))}
    </FlexRow>
  );
};

export default CenterCardCollection;
