import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { colors } from '@guryou/colors';
import { DefaultText, FlexColumn, FlexRow, MediumText, openModal, OutlinedButton } from '@guryou/html-components';
import { borderDefinition, boxShadow, smallBorderRadius } from '@guryou/html-theme';
import { printDate, printPrice, printTime } from '@guryou/utils';
import { useTheme } from '@material-ui/core/styles';

const ServiceCard = ({ item, isNotVideo = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleShowSessions = () => dispatch(openModal('sessions', { service: item }, `${item.name} (${t('SESSIONS.titlePlural')})`));

  return (
    <FlexRow boxShadow={boxShadow} marginBottom={8} xs={3} borderRadius={smallBorderRadius} overflow="hidden" border={borderDefinition()}>
      <FlexRow margin={0} justifyContent="space-between" backgroundColor={item.color ? `#${item.color}` : theme.palette.primary.main} padding={3}>
        <DefaultText color={colors.white} xs={10}>
          {item.groupName ? `${t('GROUP.group')} - ${item.groupName}` : t('GENERAL.noGroup')}
        </DefaultText>
        <DefaultText color={colors.white} xs={2}>
          {printPrice(item.price)}
        </DefaultText>
      </FlexRow>
      <FlexColumn alignItems="flex-start" xs={11} padding={3}>
        <MediumText marginTop={2} marginBottom={3}>
          {item.name || t('GENERAL.noName')}
        </MediumText>
        <FlexRow padding={1} alignItems="center">
          <DefaultText xs={4}>{t('PROFILE.date')}: </DefaultText>
          <DefaultText color={colors.gray}>{printDate(item.date)}</DefaultText>
        </FlexRow>
        {isNotVideo && (
          <>
            <FlexRow padding={1} alignItems="center">
              <DefaultText xs={4}>{t('PROFILE.time')}: </DefaultText>
              <DefaultText color={colors.gray}>{printTime(item.date)}</DefaultText>
            </FlexRow>
            <FlexRow padding={1} alignItems="center">
              <DefaultText xs={4}>{t('PROFILE.duration')}: </DefaultText>
              <DefaultText color={colors.gray}>{`${item.duration} : ${item.timeUnit}s`}</DefaultText>
            </FlexRow>
            {item.resourceName ? (
              <FlexRow padding={1} alignItems="center">
                <DefaultText xs={4}>{t('PROFILE.resource')}: </DefaultText>
                <DefaultText color={colors.gray}>{item.resourceName}</DefaultText>
              </FlexRow>
            ) : (
              <OutlinedButton onClick={handleShowSessions}>{t('SESSIONS.titlePlural')}</OutlinedButton>
            )}
          </>
        )}
      </FlexColumn>
    </FlexRow>
  );
};

export default ServiceCard;
