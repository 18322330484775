import React from 'react';
import { useTranslation } from 'react-i18next';
import { Block, ContainedButton, DefaultText, LargeText } from '@guryou/html-components';
import { useTheme } from '@material-ui/core/styles';
import ConfirmationSubHeader from './ConfirmationSubHeader';

const ConfirmationSuccess = ({ type }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <ConfirmationSubHeader />
      <Block xs={4} margin="auto" marginTop={4} textAlign="center">
        <LargeText color={theme.palette.success.main}>{t('CONFIRMATIONS.success')}</LargeText>
        <DefaultText>{t(`CONFIRMATIONS.${type}.success`)} </DefaultText>
        {type !== 'user' && <DefaultText marginBottom={4}>{t('CONFIRMATIONS.checkEmail')} </DefaultText>}
        <ContainedButton marginTop={4} href={process.env.REACT_APP_NEXTJS_URL} text={t('CONFIRMATIONS.goToFeel')} />
      </Block>
    </>
  );
};

export default ConfirmationSuccess;
