import React from 'react';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, LargeText, MediumText, RouterLink, SearchBox } from '@guryou/html-components';
import Logos from 'modules/homepage/readSection/Logos';
import ArticleCardCollection from './ArticleCardCollection';
const ReadSection = () => {
  return (
    <FlexColumn margin={15} xs={10} marginLeft="auto" marginRight="auto">
      <LargeText marginBottom={2} fontSize="3rem" fontWeight={700}>
        Read
      </LargeText>
      <MediumText textAlign="center" marginBottom={5} fontSize="1.4rem" fontWeight={400}>
        Blog: tutti i consigli per il tuo benessere
      </MediumText>
      <Logos />
      <SearchBox placeholder="Ricerca negli articoli per 'parole chiave'"></SearchBox>
      <FlexRow justifyContent="space-between" margin={10} alignItems="center">
        <MediumText fontSize="2rem" fontWeight={700}>
          Articoli recenti
        </MediumText>
        <RouterLink to="#">
          <MediumText fontSize="1rem" fontWeight={400} color={colors.mandy}>
            Scopri di piú
          </MediumText>
        </RouterLink>
      </FlexRow>
      <ArticleCardCollection />
    </FlexColumn>
  );
};

export default ReadSection;
