import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EntityTypes } from '@guryou/core';
import { useEffectOnce, useUpdateEffect } from '@guryou/hooks';
import { Block, SmallGrayText, Spinner } from '@guryou/html-components';
import { createSubscriptionReset, getPublicClasses, stateIsLoading, stateIsNotReady, StateStatus } from '@guryou/redux';
import WidgetClassItem from './WidgetClassItem';
import { WidgetContext } from './WidgetContext';

const WidgetClasses = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeServiceId, activeTab, setSelectedSessions, setSessionActiveStep, setError } = useContext(WidgetContext);
  const { classes, subscriptionCreate } = useSelector(state => state.widget);

  useEffectOnce(() => {
    dispatch(getPublicClasses(activeServiceId));
  });

  useUpdateEffect(() => {
    if (subscriptionCreate.status === StateStatus.LOADED) {
      if (subscriptionCreate.data.clientSecret) {
        const token = btoa(JSON.stringify({ ...subscriptionCreate.data, returnUrl: window.location.href }));
        window.location = `${process.env.REACT_APP_PAYMENT_APP_URL}/${token}`;
      } else {
        setSessionActiveStep(0);
        setSelectedSessions([]);
        dispatch(createSubscriptionReset());
      }
    }
    if (subscriptionCreate.status === StateStatus.ERROR) {
      setError(subscriptionCreate.errors);
    }
  }, [dispatch, setError, setSelectedSessions, setSessionActiveStep, subscriptionCreate]);

  if (!activeServiceId || stateIsNotReady(classes)) {
    return null;
  }
  if (stateIsLoading(classes)) {
    return <Spinner />;
  }
  if (!classes.data.length) {
    return (
      <SmallGrayText marginLeft={2} marginBottom={2}>
        {activeTab === EntityTypes.course && t('CLASSES.noClassesInCourse')}
        {activeTab === EntityTypes.event && t('CLASSES.noClassesInEvent')}
      </SmallGrayText>
    );
  }
  return (
    <Block margin={0}>
      {classes.data.map(c => (
        <WidgetClassItem key={c.id} gyClassId={c.id} />
      ))}
    </Block>
  );
};

export default WidgetClasses;
