import React from 'react';
import { colors } from '@guryou/colors';
import { Block, FlexColumn, FlexRow, Itunes, LargeText, MediumText } from '@guryou/html-components';
import GooglePlayLogo from '@guryou/html-components/src/assets/google-play.png';
import { borderDefinition, smallBorderRadius } from '@guryou/html-theme';
import { extraLargeBorderRadius } from '@guryou/html-theme/src/constants';

const CTASection = () => {
  return (
    <FlexColumn margin={0} textAlign="center" alignItems="center" xs={10} marginLeft="auto" marginRight="auto">
      <LargeText fontSize="1.75rem" fontWeight={700}>
        Scarica e installa l'app di Guryou
      </LargeText>
      <MediumText fontSize="1.5rem" fontWeight={400}>
        Fai ancora di piú con l'app di Guryou
      </MediumText>
      <FlexRow alignItems="center">
        <FlexRow xs={3} marginRight={10}>
          <FlexColumn justifyContent="space-between" minWidth={200}>
            <MediumText fontSize="1.25rem" fontWeight={400}>
              Cerca servizi
            </MediumText>
            <MediumText fontSize="1.25rem" fontWeight={400}>
              Gestisci il tuo profilo
            </MediumText>
          </FlexColumn>
          <FlexColumn>
            <Block width={25} height={25} borderRadius={extraLargeBorderRadius} border={borderDefinition('black', '2px')} marginBottom={0}></Block>
            <Block width={2} height={150} backgroundColor={colors.black} marginTop={0} marginBottom={0}></Block>
            <Block width={25} height={25} borderRadius={extraLargeBorderRadius} border={borderDefinition('black', '2px')} marginTop={0}></Block>
          </FlexColumn>
        </FlexRow>
        <FlexRow xs={6} alignItems="center" justifyContent="center">
          <Block
            xs={3}
            height={45}
            borderRadius={smallBorderRadius}
            backgroundImage={`url('${Itunes}')`}
            backgroundRepeat="no-repeat"
            backgroundPosition="center center"
            backgroundSize="contain"
          ></Block>
          <Block
            xs={6}
            height={600}
            backgroundImage={`url('https://wallpaperaccess.com/full/414469.jpg')`}
            backgroundRepeat="no-repeat"
            backgroundPosition="center center"
            backgroundSize="contain"
          ></Block>
          <Block
            xs={3}
            height={45}
            borderRadius={smallBorderRadius}
            backgroundImage={`url('${GooglePlayLogo}')`}
            backgroundRepeat="no-repeat"
            backgroundPosition="center center"
            backgroundSize="contain"
          ></Block>
        </FlexRow>
        <FlexRow xs={3} marginLeft={10}>
          <FlexColumn>
            <Block width={25} height={25} borderRadius={extraLargeBorderRadius} border={borderDefinition('black', '2px')} marginBottom={0}></Block>
            <Block width={2} height={150} backgroundColor={colors.black} marginTop={0} marginBottom={0}></Block>
            <Block width={25} height={25} borderRadius={extraLargeBorderRadius} border={borderDefinition('black', '2px')} marginTop={0}></Block>
          </FlexColumn>
          <FlexColumn justifyContent="space-between" minWidth={200}>
            <MediumText fontSize="1.25rem" fontWeight={400}>
              Servizi prenotati
            </MediumText>
            <MediumText fontSize="1.25rem" fontWeight={400}>
              Segui il nostro blog
            </MediumText>
          </FlexColumn>
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
};

export default CTASection;
