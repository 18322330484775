import React from 'react';
import { useSelector } from 'react-redux';
import { Block, Spinner } from '@guryou/html-components';
import { stateIsLoading, stateIsNotReady } from '@guryou/redux';
import WidgetSubServiceItem from './WidgetSubServiceItem';

const WidgetSubServices = () => {
  const { subServices } = useSelector(state => state.widget);

  if (stateIsNotReady(subServices)) {
    return null;
  }
  if (stateIsLoading(subServices)) {
    return <Spinner />;
  }
  return (
    <Block margin={0}>
      {subServices.data.map(subService => (
        <WidgetSubServiceItem key={subService.id} subService={subService} />
      ))}
    </Block>
  );
};

export default WidgetSubServices;
