import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EntityTypes } from '@guryou/core';
import { Block, ContainedButton, ExpandMoreIcon, FlexRow, Form, Title } from '@guryou/html-components';
import { generatePaymentIntent, stateIsNotReady } from '@guryou/redux';
import { printPrice } from '@guryou/utils';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import WidgetContactForm from './WidgetContactForm';
import { WidgetContext } from './WidgetContext';

const WidgetVideoDetails = ({ video }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { activeServiceId, activeTab, setActiveServiceId } = useContext(WidgetContext);
  const { videos } = useSelector(state => state.widget);

  if (stateIsNotReady(videos)) {
    return null;
  }

  return (
    <Accordion square={true} expanded={activeServiceId === video.id} onChange={(_, isExpanded) => setActiveServiceId(isExpanded ? video.id : null)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Title title={video.name} margin={4} />
        {activeServiceId !== video.id && <ContainedButton text={t('GENERAL.buy')} margin={1} xs={4} color="primary" />}
      </AccordionSummary>
      {activeServiceId === video.id && activeTab === EntityTypes.video && (
        <AccordionDetails>
          <Block margin={0}>
            <Block margin={4} padding={2} dangerouslySetInnerHTML={{ __html: video.description }} />
            <Form
              parserVersion={2}
              actions={false}
              onSubmit={({ email, firstname, lastname, phone }) =>
                dispatch(generatePaymentIntent({ email, firstname, lastname, phone, productId: video.id }))
              }
            >
              <FlexRow justifyContent="center" alignItems="center">
                <WidgetContactForm />
                <ContainedButton xs={6} text={`${t('GENERAL.pay')} ${printPrice(video.price)}`} type="submit" color="primary" maxWidth="10rem" />
              </FlexRow>
            </Form>
          </Block>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default WidgetVideoDetails;
