import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TextButton, Toolbar as ToolBar } from '@guryou/html-components';
import i18n from 'i18n';

const Toolbar = ({ setOpenContact, setOpenDownload }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const handleLanguage = lang => {
    i18n.changeLanguage(lang);
  };
  return (
    <ToolBar justifyContent="flex-end">
      <TextButton color="white" onClick={() => handleLanguage('en')} text={t('GENERAL.en')} />
      <TextButton color="white" onClick={() => handleLanguage('it')} text={t('GENERAL.it')} />
      <TextButton color="white" onClick={() => history.push('/about')} text={t('NAVBAR.about')} />
      <TextButton color="white" onClick={() => setOpenContact(true)} text={t('NAVBAR.contactUs')} />
      <TextButton color="white" onClick={() => window.location.replace(process.env.REACT_APP_GURYOU_BIZZ)} text={t('NAVBAR.registerBusiness')} />
      <TextButton color="white" onClick={() => setOpenDownload(true)} text={t('NAVBAR.downloadApp')} />
    </ToolBar>
  );
};

export default Toolbar;
