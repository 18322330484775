import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserAccount, StateStatus } from '@guryou/redux';
import { useEffectOnSuccess } from '.';
import { resetState } from '../actions';
import { CURRENT_USER, UPDATE_USER } from '../new';

var useCurrentUser = function useCurrentUser() {
  var dispatch = useDispatch();

  var _useSelector = useSelector(function (state) {
    return state.user.current;
  }),
      data = _useSelector.data,
      status = _useSelector.status,
      version = _useSelector.version,
      entity = _useSelector.entity;

  useEffect(function () {
    if (status === StateStatus.NOT_INITIALIZED) {
      dispatch(getCurrentUserAccount());
    }
  }, [dispatch, status]);
  useEffectOnSuccess(function (state) {
    return state.user.update;
  }, function () {
    dispatch(resetState(CURRENT_USER));
    dispatch(resetState(UPDATE_USER));
  });
  return {
    user: data,
    status: status,
    version: version,
    entity: entity
  };
};

export default useCurrentUser;