import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@guryou/colors';
import { Status } from '@guryou/core';
import { FlexColumn, FlexRow, LargeText, MediumText, Modal } from '@guryou/html-components';
import { dateIsAfter, dateIsBefore } from '@guryou/utils';
import Sessions from './Sessions';
import ServiceCard from '../../components/ServiceCard';

const ServiceCards = ({ view, data, status, isNotVideo = false }) => {
  const { t } = useTranslation();

  const cards = isNotVideo
    ? status === Status.upcoming
      ? data.filter(x => x.type && dateIsAfter(x.startTime))
      : data.filter(x => x.type && dateIsBefore(x.startTime))
    : data;

  return (
    <>
      <FlexColumn alignItems="flex-start">
        <FlexRow xs={12} alignItems="center" justifyContent="flex-start">
          <LargeText margin={10}>{view.charAt(0).toUpperCase() + view.slice(1)}</LargeText>
          <MediumText color={colors.gray}>({status})</MediumText>
        </FlexRow>
        <FlexRow xs={12} flexWrap="wrap" justifyContent="flex-start">
          {cards.length ? (
            cards.map((x, i) => <ServiceCard key={i + Date.now()} item={x} isNotVideo={isNotVideo} />)
          ) : (
            <MediumText>
              No {t(`PROFILE.${status}`)} {t(`GENERAL.${view}s`)}
            </MediumText>
          )}
        </FlexRow>
      </FlexColumn>
      <Modal id="sessions" maxWidth="md">
        <Sessions />
      </Modal>
    </>
  );
};

export default ServiceCards;
