import _slicedToArray from "/Users/petarbozhinovski/projects/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

var useGender = function useGender() {
  var useTargetEnums = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      gender = _useState2[0],
      setGender = _useState2[1];

  var defaultValues = [{
    label: t('GENERAL.male'),
    value: useTargetEnums ? 'male' : 'M'
  }, {
    label: t('GENERAL.female'),
    value: useTargetEnums ? 'female' : 'F'
  }];

  if (!gender && !useTargetEnums) {
    setGender(defaultValues);
  }

  if (!gender && useTargetEnums) {
    setGender([].concat(defaultValues, [{
      label: t('GENERAL.both'),
      value: 'all'
    }]));
  }

  return gender;
};

export { useGender };