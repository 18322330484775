export const SelectCardInformation = [
  {
    id: '1',
    image: 'https://wallpaperaccess.com/full/414469.jpg',
    title: 'Book',
    description1: 'Usa il servizio di ricerca per prenotare le tue esperienze wellness',
    description2: 'Sfoglia il catalogo di attività ed esperienze',
    description3: 'Scopri tutte le esperienze wellness',
  },
  {
    id: '2',
    image: 'https://wallpaperaccess.com/full/414469.jpg',
    title: 'Read',
    description1: 'Tutti i consigli per il tuo benessere',
    description2: 'Migliori consigli per il tuo benessere',
    description3: '',
  },
  {
    id: '3',
    image: 'https://wallpaperaccess.com/full/414469.jpg',
    title: 'Video',
    description1: 'Classi online per il tuo benessere',
    description2: 'Accedi via internet a lezioni video da qualsiasi posto',
    description3: '',
  },
];
