import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexRow, TextBox } from '@guryou/html-components';
import { WidgetContext } from './WidgetContext';

const WidgetContactForm = ({ margin = 'normal' }) => {
  const { t } = useTranslation();
  const { askFirstname, askLastname, askPhone } = useContext(WidgetContext);

  let emailFieldSizes = { xs: 12, md: 6, lg: 3 };
  if (!askFirstname && !askLastname && !askPhone) {
    emailFieldSizes = { xs: 12, md: 6, lg: 6 };
  }

  return (
    <FlexRow xs={12}>
      <TextBox required={true} {...emailFieldSizes} margin={margin} fullWidth={true} name="email" label={t('GENERAL.email')} />
      {askPhone && <TextBox required={true} xs={12} md={6} lg={3} margin={margin} fullWidth={true} name="phone" label={t('GENERAL.phone')} />}
      {askFirstname && (
        <TextBox required={true} xs={12} md={6} lg={3} margin={margin} fullWidth={true} name="firstname" label={t('PROFILE.firstName')} />
      )}
      {askLastname && (
        <TextBox required={true} xs={12} md={6} lg={3} margin={margin} fullWidth={true} name="lastname" label={t('PROFILE.lastName')} />
      )}
    </FlexRow>
  );
};

export default WidgetContactForm;
