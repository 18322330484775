import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppBar, ContainedButton, FlexRow, ImageLogo, LinkButton, Modal, openModal, resetModal, Toolbar } from '@guryou/html-components';
import { sizes } from '@guryou/html-theme';
import { authCookie, cognitoLogout, handleLoginFromCookie, logoutCookie } from '@guryou/redux';
import Logo from 'assets/img/logo.png';
import ForgotPassword from 'containers/authComponents/ForgotPassword';
import Login from 'containers/authComponents/Login';
import SignUp from 'containers/authComponents/SignUp';
import ProfileMenu from './ProfileMenu';

const menuItems = [
  { label: 'READ', route: `/read` },
  { label: 'BOOK', route: `/book` },
  { label: 'VIDEO', route: `/videos` },
];

const SubHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { auth } = useSelector(state => state);
  const hasAuthData = !auth.data;

  const [view, setView] = useState();

  useEffect(() => {
    if (authCookie() && hasAuthData) {
      dispatch(handleLoginFromCookie(authCookie()));
    }
    if (!hasAuthData) {
      dispatch(resetModal());
    }
  }, [dispatch, hasAuthData]);

  const handleLogInOrLogOut = viewType => {
    if (auth.data) {
      dispatch(cognitoLogout());
      logoutCookie();
      return;
    }
    setView(viewType);
    dispatch(openModal('modal'));
  };

  return (
    <>
      <AppBar color="inherit" position="static" minHeight={sizes.appBarHeight}>
        <Toolbar>
          <FlexRow margin={0} xs={4} justifyContent="space-around" alignItems="center">
            <ImageLogo src={Logo} marginRight={5} onClick={() => history.push('/')} />
            {menuItems.map((element, idx) => (
              <LinkButton fontWeight={500} key={`${idx}_${element.label}`} to={element.route} fontSize="1.2rem">
                {element.label}
              </LinkButton>
            ))}
          </FlexRow>
          <FlexRow xs={2} margin={0} marginLeft="auto" justifyContent="flex-end">
            {auth.data ? (
              <ProfileMenu />
            ) : (
              <>
                <ContainedButton color="primary" text={t(`GENERAL.${auth.data ? 'logout' : 'login'}`)} onClick={() => handleLogInOrLogOut('login')} />
                <ContainedButton color="secondary" text={t('GENERAL.register')} onClick={() => handleLogInOrLogOut('register')} />
              </>
            )}
          </FlexRow>
        </Toolbar>
      </AppBar>
      <Modal key="loginModal" id="modal" title={t(`GENERAL.${view}`)}>
        {view === 'login' ? <Login setView={setView} /> : view === 'register' ? <SignUp /> : <ForgotPassword />}
      </Modal>
    </>
  );
};

export default SubHeader;
