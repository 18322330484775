import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentSettings, PricingModel } from '@guryou/core';
import { Block, ContainedButton, DefaultText, FlexRow, Form, OutlinedButton, Spinner, Title, ValidationSummary } from '@guryou/html-components';
import { borderDefinition } from '@guryou/html-theme';
import { createSubscriptionAsAnonymous, getSubscriptionPrice, stateIsNotReady } from '@guryou/redux';
import { isValidEmail, printDate, printPrice, printTime } from '@guryou/utils';
import { useTheme } from '@material-ui/core/styles';
import WidgetContactForm from './WidgetContactForm';
import { WidgetContext } from './WidgetContext';

const WidgetSubscription = ({ gyClass }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { sessions, subscriptionPrice } = useSelector(state => state.widget);
  const { selectedSessions, setSessionActiveStep, error, acceptPayments } = useContext(WidgetContext);

  useEffect(() => {
    dispatch(getSubscriptionPrice({ classId: +gyClass.id, sessions: selectedSessions }));
  }, [dispatch, gyClass.id, selectedSessions]);

  if (stateIsNotReady(subscriptionPrice)) {
    return <Spinner />;
  }

  const onSubmit = data => {
    if (!isValidEmail(data.email)) {
      return;
    }
    dispatch(createSubscriptionAsAnonymous({ ...data, classId: +gyClass.id, sessions: selectedSessions }));
  };

  let errorContent = null;
  if (error) {
    if (Array.isArray(error)) {
      errorContent = <ValidationSummary marginLeft={0} errorMessage={t(`VALIDATIONS.${error[0].message}`)} />;
    }
  }

  return (
    <Block>
      <Form parserVersion={2} onSubmit={onSubmit} actions={false}>
        <Block maxHeight="11rem" overflowY="auto">
          <DefaultText marginBottom={3} color={theme.palette.primary.main}>
            {t('BOOK_WIDGET.sessionsSelected')}:
          </DefaultText>
          <FlexRow alignItems="center" justifyContent="space-between" borderBottom={borderDefinition()} paddingBottom={1} paddingTop={1}>
            <DefaultText xs={4}>{t('CALENDAR.date')}</DefaultText>
            <DefaultText xs={4}>{t('SERVICES.startTime')}</DefaultText>
            <DefaultText xs={4}>{t('SERVICES.endTime')}</DefaultText>
          </FlexRow>
          {sessions.data.map(
            x =>
              (gyClass.model === PricingModel.total || selectedSessions.includes(+x.id)) && (
                <FlexRow
                  key={`details_${x.id}`}
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom={borderDefinition()}
                  paddingBottom={1}
                  paddingTop={1}
                >
                  <DefaultText xs={4}>{printDate(x.startTime)}</DefaultText>
                  <DefaultText xs={4}>{printTime(x.startTime)}</DefaultText>
                  <DefaultText xs={4}>{printTime(x.endTime)}</DefaultText>
                </FlexRow>
              )
          )}
        </Block>
        <FlexRow justifyContent="space-between">
          <Title xs={4} title={printPrice(subscriptionPrice.data.price)} description={t('BOOK_WIDGET.totalCost')} />
          <Title xs={4} title={gyClass.duration} description={t('GENERAL.duration')} />
          <Title xs={4} title={gyClass.model} description={t('BOOK_WIDGET.pricingModel')} />
        </FlexRow>
        <FlexRow justifyContent="space-between" alignItems="baseline" marginTop={2} marginLeft={-3} marginRight={-3}>
          <WidgetContactForm />
          <FlexRow xs={12} justifyContent="center">
            <OutlinedButton color="secondary" onClick={() => setSessionActiveStep(s => s - 1)}>
              {t('GENERAL.goBack')}
            </OutlinedButton>

            {[PaymentSettings.NoPayments, PaymentSettings.Optional].includes(acceptPayments) && (
              <ContainedButton
                name="onlyBook"
                value="true"
                type="submit"
                color="primary"
                text={`${t('NEWSLETTER.subscribe')} ${printPrice(subscriptionPrice.data.priceWithoutDiscount)}`}
              />
            )}
            {[PaymentSettings.OnlineOnly, PaymentSettings.Optional].includes(acceptPayments) && (
              <ContainedButton type="submit" color="primary" text={`${t('GENERAL.payNow')} ${printPrice(subscriptionPrice.data.price)}`} />
            )}
          </FlexRow>
        </FlexRow>
        <Block>{errorContent}</Block>
      </Form>
    </Block>
  );
};

export default WidgetSubscription;
