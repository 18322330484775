import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { AppBar, Modal } from '@guryou/html-components';
import Contact from 'containers/ContactUs';
import Toolbar from './Toolbar';
import DownloadApp from '../../DownloadApp';

const Header = () => {
  const { t } = useTranslation();

  const [openDownload, setOpenDownload] = useState(false);
  const [openContact, setOpenContact] = useState(false);

  return (
    <>
      <AppBar position="static">
        <Toolbar setOpenContact={setOpenContact} setOpenDownload={setOpenDownload} />
      </AppBar>
      <Modal title={t('NAVBAR.contactUs')} open={openContact} id="contactUs" close={() => setOpenContact(false)}>
        <Contact />
      </Modal>
      <Modal id="downloadApp" close={() => setOpenDownload(false)} open={openDownload} title={t('NAVBAR.downloadApp')}>
        <DownloadApp />
      </Modal>
    </>
  );
};

export default withRouter(Header);
