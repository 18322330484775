import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Block, ContainedButton, ExpandMoreIcon, FlexRow, Form, FormActions, SmallGrayText, Title } from '@guryou/html-components';
import { generatePaymentIntent } from '@guryou/redux';
import { printPrice } from '@guryou/utils';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import WidgetContactForm from './WidgetContactForm';

const WidgetMembershipDetail = ({ membership, openedMembershipId, setOpenedMembershipId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!membership) {
    return null;
  }

  return (
    <Accordion
      square={true}
      expanded={membership.id === openedMembershipId}
      onChange={(_, expanded) => setOpenedMembershipId(expanded ? membership.id : null)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Title title={membership.name} margin={1} xs={8} />
        {membership.id !== openedMembershipId && <ContainedButton text={t('GENERAL.buy')} margin={1} xs={4} color="primary" />}
      </AccordionSummary>
      <AccordionDetails>
        <Block margin={2}>
          <FlexRow margin={4} justifyContent="space-between" alignItems="center">
            <Title title={membership.sessionsCounter} description={t('MEMBERSHIPS.numberOfSessions')} margin={0} xs={4} />
            <Title title={printPrice(membership.price)} description={t('GENERAL.total')} margin={0} xs={4} />
          </FlexRow>
          <SmallGrayText paddingTop={2} paddingBottom={2} marginLeft={4} marginRight={4}>
            {membership.description}
          </SmallGrayText>
          {membership.id === openedMembershipId && (
            <Form
              actions={false}
              onSubmit={({ email, firstname, lastname, phone }) =>
                dispatch(generatePaymentIntent({ email, firstname, lastname, phone, membershipId: membership.id }))
              }
            >
              <WidgetContactForm />
              <FormActions>
                <ContainedButton text={`${t('GENERAL.payNow')} ${printPrice(membership.price)}`} type="submit" color="primary" />
              </FormActions>
            </Form>
          )}
        </Block>
      </AccordionDetails>
    </Accordion>
  );
};

export default WidgetMembershipDetail;
