import React from 'react';
import { Block, FlexColumn, LargeText, MediumText } from '@guryou/html-components';

const SelectCard = ({ image, title, description1, description2, description3 }) => {
  return (
    <Block
      margin={0}
      backgroundImage={`url('${image}')`}
      xs={4}
      height={350}
      background-repeat="no-repeat"
      background-position="center center"
      background-size="cover"
    >
      <FlexColumn background="#00000045" justifyContent="center" alignItems="center" height="100%">
        <LargeText color="white" fontSize="48px" fontWeight="700">
          {title}
        </LargeText>
        <MediumText color="white" fontSize="20px" fontWeight="400">
          {description1}
        </MediumText>
        <MediumText color="white" fontSize="20px" fontWeight="400">
          {description2}
        </MediumText>
        <MediumText color="white" fontSize="20px" fontWeight="400">
          {description3}
        </MediumText>
      </FlexColumn>
    </Block>
  );
};

export default SelectCard;
