import _objectSpread from "/Users/petarbozhinovski/projects/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { CSDT, CSSC, CSST } from '../types';
var initialState = {
  client: null,
  token: null
};

var crossStorage = function crossStorage() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case CSSC:
      return _objectSpread(_objectSpread({}, state), {}, {
        client: action.client
      });

    case CSST:
      return _objectSpread(_objectSpread({}, state), {}, {
        token: action.token
      });

    case CSDT:
      return _objectSpread(_objectSpread({}, state), {}, {
        token: null
      });

    default:
      return state;
  }
};

export { crossStorage };