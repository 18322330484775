import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Block,
  DefaultText,
  FacebookIcon,
  FlexColumn,
  FlexRow,
  GooglePlay,
  ImageLogo,
  ImageMedium,
  InstagramIcon,
  Itunes,
  LargeText,
  LinkButton,
  LinkedInIcon,
  Modal,
  PinterestIcon,
  TwitterIcon,
} from '@guryou/html-components';
import { useTheme } from '@material-ui/core/styles';
import Logo from 'assets/img/guryouLogos/gyLogo09.png';
import Contact from 'containers/ContactUs';
import DownloadApp from './DownloadApp';

const Footer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const [openDownload, setOpenDownload] = useState(false);
  const [openContact, setOpenContact] = useState(false);

  const content = [
    {
      title: t('FOOTER.discover'),
      actions: [
        {
          label: t('FOOTER.book'),
          action: () => history.push('/book'),
        },
        {
          label: t('FOOTER.read'),
          action: () => history.push('/read'),
        },
        {
          label: t('FOOTER.video'),
          action: () => history.push('/video'),
        },
        {
          label: t('FOOTER.downloadApp'),
          action: () => setOpenDownload(true),
        },
      ],
    },
    {
      title: t('FOOTER.business'),
      actions: [
        {
          label: t('FOOTER.registerBusiness'),
          action: () => window.location.replace('https://guryou.biz/'),
        },
      ],
    },
    {
      title: 'Guryou',
      actions: [
        {
          label: t('FOOTER.about'),
          action: () => history.push('/about'),
        },
        {
          label: t('FOOTER.contactUs'),
          action: () => setOpenContact(true),
        },
        {
          label: t('FOOTER.terms'),
          action: () => window.location.replace('https://www.iubenda.com/terms-and-conditions/8084171'),
        },
        {
          label: t('FOOTER.privacyPolicy'),
          action: () => window.location.replace('https://www.iubenda.com/privacy-policy/39480488'),
        },
        {
          label: t('FOOTER.cookiePolicy'),
          action: () => window.location.replace('https://www.iubenda.com/privacy-policy/39480488/cookie-policy'),
        },
      ],
    },
  ];
  return (
    <Block margin={0} background={theme.palette.primary.main} padding={4}>
      <FlexRow justifyContent="space-between" xs={12}>
        <ImageMedium src={Logo} maxHeight={80} />
        <FlexRow justifyContent="space-evenly" xs={8}>
          {content.map(item => (
            <FlexColumn alignItems="flex-start" key={item.title} paddingLeft={5} paddingRight={5}>
              <LargeText color={theme.palette.primary.contrastText} marginTop={5} marginBottom={5}>
                {item.title}
              </LargeText>
              {item.actions.map(key => (
                <DefaultText color={theme.palette.primary.contrastText} key={key.label} marginBottom={4} onClick={key.action}>
                  {key.label}
                </DefaultText>
              ))}
            </FlexColumn>
          ))}
        </FlexRow>
        <Block width="100%" height="1px" backgroundColor={theme.palette.primary.contrastText} marginTop={10} marginBottom={4} />
        <FlexRow justifyContent="space-between" xs={12} margin={0}>
          <FlexRow xs={4}>
            <LinkButton to="https://www.facebook.com/guryou/" margin={4}>
              <FacebookIcon style={{ fill: 'white' }} fontSize="large" />
            </LinkButton>
            <LinkButton to="https://twitter.com/guryou" margin={4}>
              <TwitterIcon style={{ fill: 'white' }} fontSize="large" />
            </LinkButton>
            <LinkButton to="https://www.linkedin.com/company/gy" margin={4}>
              <LinkedInIcon style={{ fill: 'white' }} fontSize="large" />
            </LinkButton>
            <LinkButton to="https://www.pinterest.it/guryoupin/guryou/" margin={4}>
              <PinterestIcon style={{ fill: 'white' }} fontSize="large" />
            </LinkButton>
            <LinkButton to="https://www.instagram.com/guryou_official/" margin={4}>
              <InstagramIcon style={{ fill: 'white' }} fontSize="large" />
            </LinkButton>
          </FlexRow>

          <DefaultText xs={4} color={theme.palette.primary.contrastText} textAlign="center">
            &copy; {t('FOOTER.copyright', { currentYear: new Date().getFullYear() })}
          </DefaultText>

          <FlexRow xs={4} justifyContent="flex-end">
            <ImageLogo width={125} height={40} src={GooglePlay} margin={1} />
            <ImageLogo width={125} height={40} src={Itunes} margin={1} />
          </FlexRow>
        </FlexRow>
      </FlexRow>

      <Modal title={t('NAVBAR.contactUs')} id="contactUs" close={() => setOpenContact(false)} open={openContact}>
        <Contact />
      </Modal>
      <Modal id="downloadApp" title={t('NAVBAR.downloadApp')} close={() => setOpenDownload(false)} open={openDownload}>
        <DownloadApp />
      </Modal>
    </Block>
  );
};

export default Footer;
