var cookieName = 'accInfo';

var authCookie = function authCookie() {
  var acc;
  var value = document.cookie;
  var parts = value.split(cookieName + '=');

  if (parts.length > 1) {
    if (parts[0].includes('users_id') || parts[1].includes('users_id')) {
      acc = JSON.parse(parts.pop().split(';').shift());
      return acc;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

var logoutCookie = function logoutCookie() {
  var accInfoValue = '';
  var myDate = new Date();
  myDate.setMonth(myDate.getMonth() + 12);
  document.cookie = "".concat(cookieName, "=").concat(accInfoValue, ";expires=").concat(myDate, ";domain=").concat(process.env.REACT_APP_HYBRID_SUBDOMAIN, ";path=/");
};

var loginCookie = function loginCookie(cookieValue) {
  var accInfoValue = JSON.stringify(cookieValue);
  var myDate = new Date();
  myDate.setMonth(myDate.getMonth() + 12);
  document.cookie = "".concat(cookieName, "=").concat(accInfoValue, ";expires=").concat(myDate, ";domain=").concat(process.env.REACT_APP_HYBRID_SUBDOMAIN, ";path=/");
};

export { authCookie, logoutCookie, loginCookie };