import React from 'react';
import { useTranslation } from 'react-i18next';
import { Block, CheckBox, HiddenField, PaperBlock, SmallText } from '@guryou/html-components';
import { parseAsBool } from '@guryou/utils';

const Agreements = ({ agreements }) => {
  const { t } = useTranslation();

  if (!agreements) {
    return null;
  }

  const reorderedAgreements = agreements.sort((a, b) => (b === 'sharing' ? a - b : a + b));

  return (
    <PaperBlock display="flex" flexDirection="column" textAlign="left" xs={4}>
      <SmallText fontWeight="700" margin={1} marginLeft={4}>
        {t('ACCOUNT.data')}
      </SmallText>
      <SmallText margin={1} marginLeft={4}>
        {t('ACCOUNT.personalInfo')}
      </SmallText>
      {reorderedAgreements.map((x, idx) => (
        <Block xs={12} key={idx}>
          <HiddenField key={`hf_${idx}`} value={x.type} name={`agreements[${idx}][type]`} />
          {x.type === 'sharing' && (
            <SmallText margin={1} marginLeft={4}>
              {t('ACCOUNT.dataAgree')}
            </SmallText>
          )}
          <CheckBox
            key={`cb_${idx}`}
            value="Y"
            name={`agreements[${idx}][decision]`}
            label={t(`ACCOUNT.agreement${x.type}`)}
            fullWidth={true}
            checked={parseAsBool(x.decision)}
          />
        </Block>
      ))}
    </PaperBlock>
  );
};

export default Agreements;
