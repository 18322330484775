import React from 'react';
import { useSelector } from 'react-redux';
import { Block, Spinner } from '@guryou/html-components';
import { stateIsLoading, stateIsNotInitialized } from '@guryou/redux';
import WidgetVideoDetails from './WidgetVideoDetails';

const WidgetVideos = () => {
  const videos = useSelector(state => state.widget.videos);

  if (stateIsNotInitialized(videos)) {
    return null;
  }
  if (stateIsLoading(videos)) {
    return <Spinner />;
  }

  return (
    <Block margin={0}>
      {videos.data.map((video, idx) => (
        <WidgetVideoDetails key={`video_${idx}`} video={video} />
      ))}
    </Block>
  );
};

export default WidgetVideos;
