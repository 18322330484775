import React, { createElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarIcon, FlexColumn, PaymentCardIcon, ProfileIcon, SmallText, Tab, Tabs } from '@guryou/html-components';

const ProfileTab = ({ title, icon, id }) => (
  <Tab
    id={id}
    title={
      <FlexColumn>
        <SmallText>{title}</SmallText>
        {icon && createElement(icon)}
      </FlexColumn>
    }
  />
);

const ProfileTabs = ({ value, handleChange }) => {
  const { t } = useTranslation();

  return (
    <Tabs variant="fullWidth" defaultValue={value} onChange={handleChange}>
      <ProfileTab id={0} title={t('PROFILE.accountInfo')} icon={ProfileIcon} />
      <ProfileTab id={1} title={t('PROFILE.myReservations')} icon={CalendarIcon} />
      <ProfileTab id={2} title={t('PROFILE.paymentHistory')} icon={PaymentCardIcon} />
    </Tabs>
  );
};

export default ProfileTabs;
