import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@guryou/colors';
import { Block, CheckBox, FlexColumn, Form, LargeText, MailBox, NewsLetterImage, OutlinedButton, PaperBlock } from '@guryou/html-components';
import { useTheme } from '@material-ui/core/styles';

const NewsLetters = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [privacyAgreed, setPrivacyAgreed] = useState(false);

  return (
    <Block
      margin={0}
      xs={12}
      height="550px"
      textAlign="center"
      backgroundImage={`url(${NewsLetterImage})`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center center"
      backgroundAttachment="fixed"
    >
      <FlexColumn background={colors.blackAlpha} height="100%" width="100%" margin={0} justifyContent="center" alignItems="center">
        <PaperBlock backgroundColor={colors.blackAlpha2}>
          <LargeText fontSize="2rem" fontWeight="700" color="white">
            {t('NEWSLETTER.newsletterTitle')}
          </LargeText>

          <Form action={process.env.REACT_APP_TRANTORMIND_SUBSCRIBE} method="POST">
            <MailBox name="EMAIL" email={true} placeholder="Try Category, Center or Keyword" />
            <Block marginLeft={3} color={colors.white}>
              <CheckBox
                name="b_0ea0bfceaf50442d255a29a53_750c2c9a4a"
                label="I accept the data privacy regulation"
                onChange={value => setPrivacyAgreed(value)}
              />
            </Block>
            <OutlinedButton
              disabled={!privacyAgreed}
              type="submit"
              color="white"
              backgroundColor={privacyAgreed ? theme.palette.primary.main : theme.palette.grey[500]}
              text="Submit"
              border="none"
            />
          </Form>
        </PaperBlock>
      </FlexColumn>
    </Block>
  );
};

export default NewsLetters;
