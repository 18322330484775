import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexRow, MediumText, SliderInput, SliderLabel, SliderSpan } from '@guryou/html-components';

const Slider = ({ onChange, sliderStartingView }) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(sliderStartingView);

  const toggleSlider = () => {
    setToggle(prevState => !prevState);
    onChange && onChange(!toggle);
  };

  return (
    <FlexRow justifyContent="space-around" padding={5} xs={7} marginLeft="auto" marginRight="auto">
      <MediumText style={{ fontWeight: 800 }}>{t('GENERAL.tableView')}</MediumText>
      <SliderLabel>
        <SliderInput onChange={toggleSlider} type="checkbox" checked={toggle} />
        <SliderSpan></SliderSpan>
      </SliderLabel>
      <MediumText style={{ fontWeight: 800 }}>{t('GENERAL.cardsView')}</MediumText>
    </FlexRow>
  );
};

export default Slider;
