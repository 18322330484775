import { routerMiddleware } from 'react-router-redux';
import { modalReducer } from '@guryou/html-components';
import { feelReducer } from '@guryou/redux';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { persistCombineReducers, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'waas',
  storage,
  whitelist: ['auth', 'providerAuth'],
};

const persistedReducer = persistCombineReducers(persistConfig, { ...feelReducer, modal: modalReducer });
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = applyMiddleware(promise, thunk, createLogger(), routerMiddleware(createBrowserHistory()));
const store = createStore(persistedReducer, composeEnhancers(middleware));
const persistor = persistStore(store);

export { store, persistor };
