import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ALL_CONSTANT, EntityTypes, Status } from '@guryou/core';
import { BreadCrumbLi, BreadCrumbList, FlexRow } from '@guryou/html-components';

const BreadCrumb = ({ onChangeService, onChangeStatus }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [activeTab2, setActiveTab2] = useState(5);

  const breadCrumbLinks = [
    { text: t('GENERAL.all'), value: ALL_CONSTANT },
    { text: t('GENERAL.services'), value: EntityTypes.service },
    { text: t('GENERAL.courses'), value: EntityTypes.course },
    { text: t('GENERAL.events'), value: EntityTypes.event },
    { text: t('GENERAL.videos'), value: EntityTypes.video },
    { text: t('PROFILE.upcoming'), value: Status.upcoming },
    { text: t('PROFILE.completed'), value: Status.completed },
  ];

  const handleChange = (type, idx) => {
    if (idx <= 4) {
      setActiveTab(idx);
      onChangeService && onChangeService(type);
    } else {
      setActiveTab2(idx);
      onChangeStatus && onChangeStatus(type);
    }
  };

  return (
    <FlexRow justifyContent="space-between" xs={12}>
      <BreadCrumbList className="menu1">
        {breadCrumbLinks.map(
          (x, idx) =>
            idx <= 4 && (
              <BreadCrumbLi key={idx} className={` ${activeTab === idx ? 'li-selected' : ''}`} onClick={() => handleChange(x.value, idx)}>
                {x.text}
              </BreadCrumbLi>
            )
        )}
      </BreadCrumbList>
      <BreadCrumbList className="menu2">
        {breadCrumbLinks.map(
          (x, idx) =>
            idx > 4 && (
              <BreadCrumbLi key={idx} className={` ${activeTab2 === idx ? 'li-selected' : ''}`} onClick={() => handleChange(x.value, idx)}>
                {x.text}
              </BreadCrumbLi>
            )
        )}
      </BreadCrumbList>
    </FlexRow>
  );
};

export default BreadCrumb;
