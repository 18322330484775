import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Block, ContainedButton, ExpandMoreIcon, FlexRow, SmallGrayText, Title } from '@guryou/html-components';
import { printPrice } from '@guryou/utils';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { WidgetContext } from './WidgetContext';
import WidgetReservation from './WidgetReservation';

const WidgetSubServiceItem = ({ subService }) => {
  const { t } = useTranslation();
  const { activeSubServiceId, setActiveSubServiceId } = useContext(WidgetContext);

  if (!subService) {
    return null;
  }
  return (
    <Accordion
      square={true}
      expanded={activeSubServiceId === subService.id}
      onChange={(_, expanded) => setActiveSubServiceId(expanded ? subService.id : null)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Title title={subService.name} margin={1} xs={8} />
        {activeSubServiceId !== subService.id && <ContainedButton text={t('BOOK_WIDGET.book')} margin={1} xs={12} md={4} color="primary" />}
      </AccordionSummary>
      <AccordionDetails>
        <Block margin={2}>
          <FlexRow margin={0} marginLeft={4} marginRight={4} justifyContent="space-between" alignItems="center">
            <Title title={subService.capacity} description={t('GENERAL.capacity')} margin={0} xs={4} />
            <Title title={subService.duration} description={t('GENERAL.duration')} margin={0} xs={4} />
            <Title title={printPrice(subService.price)} description={t('GENERAL.total')} margin={0} xs={4} />
          </FlexRow>
          <SmallGrayText paddingTop={2} paddingBottom={2} marginLeft={4} marginRight={4}>
            {subService.description}
          </SmallGrayText>
          {activeSubServiceId === subService.id && <WidgetReservation subService={subService} />}
        </Block>
      </AccordionDetails>
    </Accordion>
  );
};

export default WidgetSubServiceItem;
