import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultText, FlexColumn, Form, OutlinedButton, TextAreaBox, TextBox } from '@guryou/html-components';
import { contactAction } from '@guryou/redux';

const Contact = () => {
  const [status, setStatus] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { contact } = useSelector(state => state);

  const handleSubmit = data => {
    const { name, email, subject, message } = data;
    dispatch(contactAction(name, email, subject, message));
  };

  useEffect(() => {
    if (contact.errors) {
      setStatus(t('NOTIFICATIONS.msgNotSent'));
    }
  }, [dispatch, t, contact]);

  return (
    <Form onSubmit={handleSubmit} actions={false} style={{ padding: 15 }}>
      <FlexColumn>
        <DefaultText textAlign="center" marginTop={7}>
          {t('MODALS.sendUsMsg')}
        </DefaultText>
        {status}
        <TextBox name="name" label={t('PROFILE.firstName')} textRequired />
        <TextBox name="email" label="Email" textRequired email />
        <TextBox name="subject" label={t('MODALS.subject')} textRequired />
        <TextAreaBox name="message" label={t('MODALS.message')} textRequired />
        <OutlinedButton color="primary" type="submit" text={t('MODALS.send')} />
      </FlexColumn>
    </Form>
  );
};

export default Contact;
