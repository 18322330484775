import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';
import green from '@material-ui/core/colors/green';
import indigo from '@material-ui/core/colors/indigo';
import lightGreen from '@material-ui/core/colors/lightGreen';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import teal from '@material-ui/core/colors/teal';

var stringToColor = function stringToColor(str) {
  var shade = 500;

  if (!str) {
    return deepPurple[shade];
  }

  var hash = 0;

  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var hex;

  for (var x = 0; x < 3; x++) {
    var value = hash >> x * 8 & 0xff;
    hex = ('00' + value.toString(16)).substr(-2);
  }

  var random = parseInt(hex, 16) % 10;
  var colors = [blue[shade], red[shade], deepOrange[shade], deepPurple[shade], indigo[shade], green[shade], lightGreen[shade], orange[shade], teal[shade], amber[shade]];
  return colors[random];
};

export { stringToColor };