import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Block, CustomButton, FlexColumn, Form, FormActions, PasswordBox, resetModal, SmallText, Spinner } from '@guryou/html-components';
import { smallBorderRadius } from '@guryou/html-theme';
import { cognitoChangeUserPassword, stateIsLoaded, stateIsLoading } from '@guryou/redux';
import { cognitoPasswordRequirements } from '@guryou/utils';
import { useTheme } from '@material-ui/core/styles';

const defaultState = {
  newPassword: '',
  confirmPassword: '',
};

const ChangeUserPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [ls, setLs] = useState(defaultState);
  const changeUserPassword = useSelector(state => state.cognito.changePassword);

  useEffect(() => {
    if (stateIsLoaded(changeUserPassword)) {
      dispatch(resetModal());
    }
  }, [dispatch, changeUserPassword.version, changeUserPassword]);

  const handleSubmit = ({ oldPassword, newPassword }) => {
    dispatch(cognitoChangeUserPassword(oldPassword, newPassword));
  };

  const handleInputsChange = (value, e, input) => {
    e.persist();
    setLs(prevState => ({ ...prevState, [input]: value }));
  };

  const passwordStrength = cognitoPasswordRequirements(ls.newPassword);
  const checkIfPasswordMatch = ls.newPassword === ls.confirmPassword;

  return (
    <Block textAlign="center">
      {stateIsLoading(changeUserPassword) ? (
        <Spinner />
      ) : (
        <Form onSubmit={handleSubmit} actions={false}>
          <FlexColumn>
            <PasswordBox label={t('ACCOUNT.oldPassword')} name="oldPassword" />
            <Link className="text-center" to="/forgot-password">
              {t('GENERAL.dontKnowPassword')}
            </Link>
            <PasswordBox
              onChange={(value, e) => handleInputsChange(value, e, 'newPassword')}
              name="newPassword"
              label={t('GENERAL.password')}
              autoComplete="current-password"
              required
            />
            <PasswordBox
              onChange={(value, e) => handleInputsChange(value, e, 'confirmPassword')}
              name="confirmPassword"
              label={t('PROFILE.confirmPass')}
              autoComplete="current-password"
              required
            />
          </FlexColumn>
          <FormActions>
            <CustomButton
              color="white"
              borderRadius={smallBorderRadius}
              background={theme.palette.secondary.main}
              text={t('PROVIDERS.changePassword')}
              type="submit"
            />
          </FormActions>
          <SmallText padding={2} color="red">
            {changeUserPassword.error && changeUserPassword.error.message}
          </SmallText>
          <SmallText padding={2} color="red">
            {passwordStrength && passwordStrength}
          </SmallText>
          <SmallText padding={2} color="red">
            {ls.newPassword.length >= 8 && ls.confirmPassword && !checkIfPasswordMatch && t('ACCOUNT.newAndConfirmPassDontMatch')}
          </SmallText>
        </Form>
      )}
    </Block>
  );
};

export default ChangeUserPassword;
