export const information = [
  {
    id: '1',
    name: `Lorem`,
    job: `Yoga Trainer`,
    image: `https://prodapibook.guryou.com/assets/images/providers/536-foto%20Bio.JPG`,
  },
  {
    id: '2',
    name: `Lorem`,
    job: `Yoga Trainer`,
    image: `https://prodapibook.guryou.com/assets/images/providers/57-Fulvia-Bassi-1.jpg`,
  },
  {
    id: '3',
    name: `Lorem`,
    job: `Yoga Trainer`,
    image: `https://prodapibook.guryou.com/assets/images/providers/287-20190320_163052.jpg`,
  },
  {
    id: '4',
    name: `Lorem`,
    job: `Yoga Trainer`,
    image: `https://prodapibook.guryou.com/assets/images/providers/245-Access%20bars2.jpg`,
  },
];
