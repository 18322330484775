import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EntityTypes } from '@guryou/core';
import { DynamicThemeContext, FullScreenSpinner } from '@guryou/html-components';
import { getAllPublicProducts, getPublicSubServices, stateIsLoading, stateIsNotInitialized, StateStatus, widgetGetIntegration } from '@guryou/redux';

const WidgetProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { integrationId } = useParams();
  const { setMainColor } = useContext(DynamicThemeContext);

  const [activeServiceId, setActiveServiceId] = useState(null);
  const [activeSubServiceId, setActiveSubServiceId] = useState(null);
  const [activeClassId, setActiveClassId] = useState(null);
  const [activeTab, setActiveTab] = useState();
  const [sessionActiveStep, setSessionActiveStep] = useState(0);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [error, setError] = useState(null);

  const { integration, videos, paymentIntent, reservationCreate } = useSelector(state => state.widget);
  const { providerId, configuration, provider } = integration.data || {};
  const { resources, services, courses, events, memberships } = provider || {};
  const { showEvents, showVideos, showCourses, showServices, showMemberships, primaryColor, askEmail, askFirstname, askLastname, askPhone } =
    configuration || {};

  useEffect(() => {
    if (activeTab === EntityTypes.service && activeServiceId) {
      dispatch(getPublicSubServices(activeServiceId));
    }
  }, [activeServiceId, activeTab, dispatch]);

  useEffect(() => {
    if (reservationCreate.status === StateStatus.ERROR) {
      setError(reservationCreate.errors);
    }
    if (reservationCreate.status === StateStatus.LOADED) {
      if (reservationCreate.data.clientSecret) {
        const token = btoa(JSON.stringify({ ...reservationCreate.data, returnUrl: window.location.href }));
        window.location = `${process.env.REACT_APP_PAYMENT_APP_URL}/${token}`;
      }
    }
  }, [reservationCreate, setError]);

  useEffect(() => {
    if (!providerId) {
      dispatch(widgetGetIntegration(integrationId));
    } else {
      showVideos && dispatch(getAllPublicProducts(providerId));
      primaryColor && setMainColor(primaryColor);
    }
    // eslint-disable-next-line
  }, [dispatch, providerId]);

  useEffect(() => {
    if (!providerId) {
      return;
    }
    const service =
      (showServices && services?.[0]) ||
      (showCourses && courses?.[0]) ||
      (showEvents && events?.[0]) ||
      (showMemberships && memberships?.[0]) ||
      (showVideos && videos.data?.[0]);

    if (service) {
      setActiveServiceId(service.id);
      setActiveTab(service.type || EntityTypes.video);
    } else {
      setActiveTab(
        (showServices && EntityTypes.service) ||
          (showCourses && EntityTypes.course) ||
          (showEvents && EntityTypes.event) ||
          (showVideos && EntityTypes.video) ||
          (showMemberships && EntityTypes.membership)
      );
    }
  }, [courses, dispatch, events, memberships, providerId, services, showCourses, showEvents, showMemberships, showServices, showVideos, videos.data]);

  useEffect(() => {
    switch (activeTab) {
      case EntityTypes.service:
        setActiveServiceId(services?.[0]?.id);
        break;
      case EntityTypes.course:
        setActiveServiceId(courses?.[0]?.id);
        break;
      case EntityTypes.event:
        setActiveServiceId(events?.[0]?.id);
        break;
      case EntityTypes.membership:
        setActiveServiceId(memberships?.[0]?.id);
        break;
      default:
        setActiveServiceId(videos.data?.[0]?.id);
    }
  }, [activeTab, courses, events, memberships, services, videos]);

  useEffect(() => {
    if (paymentIntent.status === StateStatus.LOADED) {
      if (!paymentIntent.data.amount) {
        setActiveServiceId(null);
        setActiveSubServiceId(null);
        setActiveClassId(null);
        return;
      }
      const token = btoa(JSON.stringify({ ...paymentIntent.data, returnUrl: window.location.href }));
      window.location = `${process.env.REACT_APP_PAYMENT_APP_URL}/${token}`;
    }
  }, [paymentIntent]);

  if (!activeTab || stateIsNotInitialized(integration) || (showVideos && stateIsNotInitialized(videos))) {
    return null;
  }
  if (stateIsLoading(integration) || (showVideos && stateIsLoading(videos))) {
    return <FullScreenSpinner />;
  }

  const value = {
    resources,
    services,
    courses,
    events,
    memberships,
    activeSubServiceId,
    setActiveSubServiceId,
    activeServiceId,
    setActiveServiceId,
    activeClassId,
    setActiveClassId,
    error,
    setError,
    activeTab,
    setActiveTab,
    sessionActiveStep,
    setSessionActiveStep,
    selectedSessions,
    setSelectedSessions,
    showEvents,
    showVideos,
    showCourses,
    showServices,
    showMemberships,
    askEmail,
    askFirstname,
    askLastname,
    askPhone,
    acceptPayments: provider.acceptPayments,
    onlineDiscount: provider.onlineDiscount,
  };

  return <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>;
};

const WidgetContext = createContext();

export { WidgetProvider, WidgetContext };
