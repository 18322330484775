import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { Block, FlexColumn, FlexRow, LockIcon, MediumText } from '@guryou/html-components';
import { borderDefinition, boxShadow } from '@guryou/html-theme';
import { printPrice, removeDuplicateObjects } from '@guryou/utils';
import { useTheme } from '@material-ui/core/styles';
import SectionDetails from './SectionDetails';

const Sections = () => {
  const theme = useTheme();
  const { product } = useLocation().state;
  const sections = product.sections && removeDuplicateObjects(product.sections).reverse();
  const files = product.files && removeDuplicateObjects(product.files);

  const [activeSection, setActiveSection] = useState(sections && sections.find(x => x.id).id);

  return (
    <FlexColumn xs={4}>
      <Block boxShadow={boxShadow} borderRadius="5px" border={borderDefinition()}>
        <FlexRow justifyContent="space-between" padding={3} backgroundColor={theme.palette.primary.main} margin={0}>
          <MediumText color={colors.white}>{product.category.name}</MediumText>
          <MediumText color={colors.white}>{printPrice(product.price)}</MediumText>
        </FlexRow>
        <FlexRow justifyContent="space-between" padding={3} margin={0}>
          <MediumText>{product.name}</MediumText>
          <Block xs={1} backgroundColor={theme.palette.primary.main} borderRadius="10px" padding={2} color={colors.white} textAlign="center">
            {<LockIcon />}
          </Block>
        </FlexRow>
        <Block
          padding={3}
          height={200}
          maxHeight={200}
          overflowY="scroll"
          textAlign="justify"
          dangerouslySetInnerHTML={{
            __html: product.productsDescription || product.description,
          }}
        ></Block>
        <Block textAlign="right" marginBottom={3} marginTop={3}>
          <MediumText fontWeight={500} marginRight={3}>
            0 / {files.length} - ( 0% Completed )
          </MediumText>
        </Block>
      </Block>
      <Block boxShadow={boxShadow} borderRadius="5px" border={borderDefinition()} marginTop={3}>
        {sections.map(x => (
          <SectionDetails section={x} key={JSON.stringify(x)} activeSection={activeSection} setActiveSection={setActiveSection} files={files} />
        ))}
      </Block>
    </FlexColumn>
  );
};

export default Sections;
