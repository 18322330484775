import React from 'react';
import { AppBar, FlexRow, ImageLogo, Toolbar } from '@guryou/html-components';
import { sizes } from '@guryou/html-theme';
import Logo from 'assets/img/logo.png';

const ConfirmationSubHeader = () => {
  return (
    <AppBar color="inherit" position="static" minHeight={sizes.appBarHeight}>
      <Toolbar>
        <FlexRow margin={0} justifyContent="space-around" alignItems="center">
          <ImageLogo src={Logo} marginRight={5} onClick={() => (window.location = process.env.REACT_APP_NEXTJS_URL)} />
        </FlexRow>
      </Toolbar>
    </AppBar>
  );
};

export default ConfirmationSubHeader;
