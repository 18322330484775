import React from 'react';
import { FlexRow } from '@guryou/html-components';
import SelectCardCollection from './SelectCardCollection';

const SelectSection = () => {
  return (
    <FlexRow margin={0} marginBottom={15}>
      <SelectCardCollection />
    </FlexRow>
  );
};

export default SelectSection;
