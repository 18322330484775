import React from 'react';
import { FlexRow } from '@guryou/html-components';
import PreviewVideo from './PreviewVideo';
import Sections from './Sections';

export const SingleVideo = () => {
  return (
    <FlexRow marginTop={7} marginBottom={7}>
      <Sections />
      <PreviewVideo />
    </FlexRow>
  );
};
