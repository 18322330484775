import { colors } from '@guryou/colors';

export const information = [
  {
    id: '1',
    image: 'https://feel.guryou.com/static/img/newcategories/8.png',
    title: 'MINDSET',
    color: colors.bayOfMany,
  },
  {
    id: '2',
    image: 'https://feel.guryou.com/static/img/newcategories/3.png',
    title: 'MOVES',
    color: colors.lightningYellow,
  },
  {
    id: '3',
    image: 'https://feel.guryou.com/static/img/newcategories/5.png',
    title: 'HEALTH',
    color: colors.pomegranate,
  },
  {
    id: '4',
    image: 'https://feel.guryou.com/static/img/newcategories/12.png',
    title: 'WELLBEING',
    color: colors.jaggedIce,
  },
  {
    id: '5',
    image: 'https://feel.guryou.com/static/img/newcategories/21.png',
    title: 'LIFESTYLE & TRAVEL',
    color: colors.fruitSalad2,
  },
  {
    id: '6',
    image: 'https://feel.guryou.com/static/img/newcategories/57.png',
    title: 'WELLNESS @ WORK',
    color: colors.orient,
  },
  {
    id: '7',
    image: 'https://feel.guryou.com/static/img/newcategories/all.png',
    title: 'VEDI TUTTI',
    color: colors.mandy,
  },
];
