import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { FlexRow, Spinner } from '@guryou/html-components';
import { stateIsLoaded } from '@guryou/redux';
import { Box, Typography } from '@material-ui/core';
import EditProfile from './EditProfile';
import PaymentHistory from './PaymentHistory';
import ProfileTabs from './ProfileTabs';
import Reservations from './Reservations';

const TabPanel = props => {
  const { children, value, index, ...rest } = props;

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} {...rest}>
      <Box p={3}>{children}</Box>
    </Typography>
  );
};

const Profile = () => {
  const [value, setValue] = useState(0);

  const auth = useSelector(state => state.auth);

  const handleChange = newValue => setValue(newValue);

  const handleChangeIndex = index => setValue(index);

  if (!stateIsLoaded(auth)) {
    return <Spinner />;
  }
  return (
    <>
      <FlexRow>
        <ProfileTabs value={value} handleChange={handleChange} />
      </FlexRow>
      <SwipeableViews axis="x" index={value} onChangeIndex={handleChangeIndex} responsive="scrollMaxHeight">
        <TabPanel value={value} index={0}>
          <EditProfile />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Reservations />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PaymentHistory />
        </TabPanel>
      </SwipeableViews>
    </>
  );
};

export default Profile;
