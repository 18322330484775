import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { EntityTypes } from '@guryou/core';
import { DefaultText, FullScreenSpinner, Tab, Tabs } from '@guryou/html-components';
import { WidgetContext } from './WidgetContext';
import WidgetMemberships from './WidgetMemberships';
import WidgetServices from './WidgetServices';
import WidgetVideos from './WidgetVideos';

const Widget = () => {
  const { t } = useTranslation();
  const {
    activeTab,
    setActiveTab,
    showEvents,
    showVideos,
    showCourses,
    showServices,
    showMemberships,
    services,
    courses,
    events,
    memberships,
  } = useContext(WidgetContext);

  const videos = useSelector(state => state.widget.videos);
  const tabsLength = [showEvents, showVideos, showCourses, showServices, showMemberships].filter(x => x).length;

  if (!tabsLength) {
    return <DefaultText>Check your widget configuration</DefaultText>;
  }

  if (tabsLength === 1) {
    if (showServices || showCourses || showEvents) {
      return <WidgetServices />;
    }
    if (showMemberships) {
      return <WidgetMemberships />;
    }
    return <WidgetVideos />;
  }

  if (!activeTab) {
    return <FullScreenSpinner />;
  }

  return (
    <Tabs defaultValue={activeTab} onChange={val => setActiveTab(val)}>
      {showServices && !!services?.length && (
        <Tab id={EntityTypes.service} title={t('SERVICES.titlePlural')}>
          <WidgetServices />
        </Tab>
      )}
      {showCourses && !!courses?.length && (
        <Tab id={EntityTypes.course} title={t('COURSES.titlePlural')}>
          <WidgetServices />
        </Tab>
      )}
      {showEvents && !!events?.length && (
        <Tab id={EntityTypes.event} title={t('EVENTS.titlePlural')}>
          <WidgetServices />
        </Tab>
      )}
      {showVideos && !!videos.data.length && (
        <Tab id={EntityTypes.video} title={t('PRODUCT_VIDEO.titlePlural')}>
          <WidgetVideos />
        </Tab>
      )}
      {showMemberships && !!memberships?.length && (
        <Tab id={EntityTypes.membership} title={t('MEMBERSHIPS.titlePlural')}>
          <WidgetMemberships />
        </Tab>
      )}
    </Tabs>
  );
};

export default Widget;
