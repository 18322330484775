import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Block, FlexColumn, FlexRow, LargeText, OutlinedButton, SearchBox, Spinner, VideoPageImage } from '@guryou/html-components';
import { getAllPublicProducts, stateIsLoaded, stateIsLoading } from '@guryou/redux';
import { useTheme } from '@material-ui/core/styles';
import VideoCard from './VideoCard';
import VideoFilters from './VideoFilters';
export { SingleVideo } from './SingleVideo';

const Videos = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const allProducts = useSelector(state => state.widget.videos);

  const [products, setProducts] = useState();
  const [filters, setFilters] = useState();
  const [cardsNumber, setCardsNumber] = useState(6);

  useEffect(() => {
    dispatch(getAllPublicProducts());
  }, [dispatch]);

  useEffect(() => {
    if (stateIsLoaded(allProducts)) {
      triggerFilter(filters);
    }
    //eslint-disable-next-line
  }, [allProducts]);

  const handleSearch = value => {
    if (!value) {
      dispatch(getAllPublicProducts());
    }
    dispatch(getAllPublicProducts(undefined, value));
  };

  const triggerFilter = filter => {
    if (!filter) {
      setProducts(allProducts.data);
      return;
    }
    const result = allProducts.data.filter(
      x =>
        (!filter.price || +filter.price > +x.price) &&
        (!filter.providerType || filter.providerType === x.provider?.type) &&
        (!filter.category || +filter.category === +x.category?.id)
    );

    setProducts(result);
  };

  return (
    <Block margin={0}>
      <Block
        margin={0}
        xs={12}
        height="550px"
        textAlign="center"
        backgroundImage={`url(${VideoPageImage})`}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPositionX="center"
        backgroundPositionY="center"
      >
        <FlexRow background="#00000060" height="100%" width="100%" justifyContent="center" alignItems="center" margin={0}>
          <LargeText fontSize="3rem" fontWeight="700" color="white">
            {t('VIDEO_COURSE_PAGE.stay')}
          </LargeText>
        </FlexRow>
      </Block>
      <FlexColumn marginTop={15}>
        <LargeText fontSize="3rem" textTransform="capitalize" marginBottom={3}>
          {t('VIDEO_COURSE_PAGE.video')}
        </LargeText>
        <LargeText>{t('VIDEO_COURSE_PAGE.forBest')}</LargeText>
      </FlexColumn>
      <Block>
        <FlexRow justifyContent="center">
          <SearchBox name="search" onChange={handleSearch} placeholder="Try Category, Center or Keyword" />
        </FlexRow>
        <VideoFilters setFilters={setFilters} triggerFilter={triggerFilter} products={allProducts.data || []} />
      </Block>

      <FlexRow xs={10} marginLeft="auto" marginRight="auto" marginTop={4} marginBottom={4}>
        {stateIsLoading(allProducts) ? (
          <Spinner />
        ) : products ? (
          products.map((product, index) => index < cardsNumber && <VideoCard product={product} key={index} />)
        ) : (
          t('PROFILE.noVideos')
        )}
      </FlexRow>

      <FlexRow justifyContent="center">
        <OutlinedButton
          onClick={() => setCardsNumber(cardsNumber + 3)}
          disabled={!products || cardsNumber > products.length}
          marginTop={7}
          marginBottom="50px"
          color="white"
          backgroundColor={!products || cardsNumber > products.length ? theme.palette.grey[500] : theme.palette.primary.main}
          text={t('HOME_READ_PAGE.seeMore')}
        />
      </FlexRow>
    </Block>
  );
};

export default Videos;
