import React from 'react';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, LargeText, MediumText, RouterLink, SearchBox } from '@guryou/html-components';
import CenterCardCollection from './CenterCardCollection';
import MemberCardCollection from './MemberCardCollection';
const BookSection = () => {
  return (
    <FlexColumn marginLeft="auto" marginRight="auto" xs={10} margin={15}>
      <LargeText marginBottom={2} fontSize="3rem" fontWeight={700}>
        Book
      </LargeText>
      <MediumText textAlign="center" marginBottom={5} fontSize="1.4rem" fontWeight={400}>
        Prenota: usa il servizio di ricerca per prenotare le tue esperienze wellness
      </MediumText>
      <SearchBox placeholder="Prova 'nome' o 'parola chiave'"></SearchBox>
      <FlexRow justifyContent="space-between" margin={10} alignItems="center">
        <MediumText fontSize="2rem" fontWeight={700}>
          I nostri centri
        </MediumText>
        <RouterLink to="#">
          <MediumText fontSize="1rem" fontWeight={400} color={colors.mandy}>
            Scopri di piú
          </MediumText>
        </RouterLink>
      </FlexRow>
      <CenterCardCollection />
      <FlexRow justifyContent="space-between" margin={10} alignItems="center">
        <MediumText fontSize="2rem" fontWeight={700}>
          I nostri professionisti
        </MediumText>
        <RouterLink to="#">
          <MediumText fontSize="1rem" fontWeight={400} color={colors.mandy}>
            Scopri di piú
          </MediumText>
        </RouterLink>
      </FlexRow>
      <MemberCardCollection />
    </FlexColumn>
  );
};
export default BookSection;
