import React, { useContext, useState } from 'react';
import { EntityTypes } from '@guryou/core';
import { Block, ExpandMoreIcon, Title } from '@guryou/html-components';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { WidgetContext } from './WidgetContext';
import WidgetMembershipDetail from './WidgetMembershipDetail';

const WidgetMemberships = () => {
  const { activeServiceId, setActiveServiceId, activeTab, memberships } = useContext(WidgetContext);
  const [openedMembershipId, setOpenedMembershipId] = useState(false);

  if (!memberships?.length) {
    return null;
  }

  return (
    <>
      {memberships.map(group => (
        <Accordion
          square={true}
          key={group.id}
          onChange={(e, isExpanded) => {
            if (e.target.tagName.toLocaleLowerCase() === 'span') {
              return false;
            }
            setActiveServiceId(isExpanded ? group.id : null);
          }}
          expanded={activeServiceId === group.id}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Title title={group.name} margin={1} />
          </AccordionSummary>
          <AccordionDetails>
            {activeTab === EntityTypes.membership && activeServiceId === group.id && (
              <Block margin={0}>
                {group.offers?.map((m, i) => (
                  <WidgetMembershipDetail
                    key={i}
                    membership={m}
                    openedMembershipId={openedMembershipId}
                    setOpenedMembershipId={setOpenedMembershipId}
                  />
                ))}
              </Block>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default WidgetMemberships;
