import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Block, ContainedButton, DefaultText, FlexRow, Form, ImageMedium, PasswordBox, Spinner, TextBox } from '@guryou/html-components';
import { cognitoLogin, CURRENT_USER, OWNER, resetState, stateIsLoading } from '@guryou/redux';
import { useTheme } from '@material-ui/core/styles';
import logo from 'assets/img/logo.png';
import { persistor } from 'store';

const Login = ({ setView }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const auth = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(resetState(CURRENT_USER));
    dispatch(resetState(OWNER));
  }, [dispatch]);

  if (stateIsLoading(auth)) {
    return <Spinner big={true} />;
  }

  const handleSubmit = data => {
    const { email, password } = data;
    dispatch(cognitoLogin(email, password, persistor));
  };

  return (
    <Block>
      <ImageMedium marginLeft="auto" marginRight="auto" src={logo} />
      <Form onSubmit={handleSubmit} actions={false}>
        <Block xs={10} marginLeft="auto" marginRight="auto">
          <TextBox key="email" name="email" label={t('GENERAL.email')} autoComplete="username" required />
          <PasswordBox key="password" name="password" label={t('GENERAL.password')} autoComplete="current-password" required />
        </Block>

        <FlexRow justifyContent="center">
          <DefaultText color={theme.palette.primary.main} onClick={() => setView('passwordForgoten')} margin={5}>
            {t('GENERAL.dontKnowPassword')}
          </DefaultText>
        </FlexRow>

        <FlexRow justifyContent="space-around">
          <ContainedButton xs={5} color="primary" text={t('GENERAL.login')} type="submit" />
          <ContainedButton xs={5} color="secondary" text={t('GENERAL.register')} onClick={() => setView('register')} />
        </FlexRow>
      </Form>
    </Block>
  );
};

export default withRouter(Login);
