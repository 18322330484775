import React from 'react';
import { FileTypes } from '@guryou/core';
import {
  Block,
  FlexColumn,
  FlexRow,
  generatePublicImageUrl,
  MediumText,
  PdfPreview,
  RouterLink,
  SmallText,
  VideoPreview,
} from '@guryou/html-components';
import { borderDefinition, boxShadow, mediumBorderRadius } from '@guryou/html-theme';
import { printPrice } from '@guryou/utils';
import { useTheme } from '@material-ui/core/styles';

const defaultSlice = 20;

const VideoCard = ({ product }) => {
  const theme = useTheme();
  const imageSrc =
    product.fileType === FileTypes.document
      ? PdfPreview
      : product.fileType === FileTypes.image
      ? generatePublicImageUrl(product.imageUrl)
      : VideoPreview;

  return (
    <FlexColumn
      xs={4}
      margin={0}
      marginTop={4}
      key={product.productsId || product.id}
      borderRadius={mediumBorderRadius}
      border={borderDefinition()}
      marginLeft={4}
      marginRight={4}
      boxShadow={boxShadow}
    >
      <RouterLink to={{ pathname: `videos/${product.id}`, state: { product } }} xs={12}>
        <FlexRow
          justifyContent="space-between"
          background={theme.palette.primary.main}
          borderTopLeftRadius={mediumBorderRadius}
          borderTopRightRadius={mediumBorderRadius}
          padding={4}
          margin={0}
          height={50}
          maxHeight={50}
        >
          <SmallText color={theme.palette.primary.contrastText}>{product?.category?.name || ''}</SmallText>
          <SmallText color={theme.palette.primary.contrastText}>{printPrice(product.price)}</SmallText>
        </FlexRow>
        <Block
          xs={12}
          height={300}
          maxHeight={300}
          margin={0}
          backgroundImage={`url(${imageSrc})`}
          backgroundSize="cover"
          backgroundPosition="center center"
        ></Block>
        <MediumText marginTop={3} marginBottom={3} textAlign="center" fontWeight={900}>
          {product.name.length >= 20 ? `${product.name.slice(0, defaultSlice)} ...` : product.name}
        </MediumText>
        <Block
          maxHeight={200}
          height={200}
          overflowY="scroll"
          padding={4}
          textAlign="justify"
          dangerouslySetInnerHTML={{
            __html: product.description,
          }}
        ></Block>
      </RouterLink>
      <FlexRow justifyContent="flex-end" paddingLeft={5} paddingRight={5} paddingTop={3} paddingBottom={3} borderTop={borderDefinition()}>
        <RouterLink to={`/centri-details/${product.provider?.id}`} fontWeight={900}>
          {product.provider?.name}
        </RouterLink>
      </FlexRow>
    </FlexColumn>
  );
};

export default VideoCard;
