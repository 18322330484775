"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GroupTypes = exports.GenderTypes = exports.ProviderTypes = exports.AgreementTypes = exports.FileTypes = exports.PricingModel = exports.ServiceTypes = exports.PaidStatus = exports.Status = exports.EntityStatus = exports.SubscriptionPackages = exports.EntityTypes = exports.ProviderMainCategories = exports.Languages = exports.MembershipTypes = exports.ResourceTypes = exports.ResourceTypeId = void 0;
var EntityTypes = {
  "break": 'break',
  "class": 'class',
  course: 'course',
  event: 'event',
  membership: 'membership',
  product: 'product',
  reservation: 'reservation',
  service: 'service',
  serviceOneTime: 'onetime',
  // old naming
  session: 'session',
  subscription: 'subscription',
  subService: 'subservice',
  video: 'video',
  workDay: 'work_day',
  holiday: 'holiday',
  audio: 'audio',
  image: 'image',
  document: 'document',
  workout: 'workout',
  plan: 'plan',
  client: 'client'
};
exports.EntityTypes = EntityTypes;
var GroupTypes = {
  course: 'course',
  event: 'event',
  membership: 'membership',
  service: 'service',
  serviceOneTime: 'onetime',
  video: 'video',
  plan: 'plan',
  client: 'client'
};
exports.GroupTypes = GroupTypes;
var MembershipTypes = {
  service: 'service',
  courseOrEvent: 'course/event',
  video: 'video',
  workout: 'workout'
};
exports.MembershipTypes = MembershipTypes;
var ServiceTypes = {
  service: 'N',
  course: 'Y',
  event: 'E',
  videos: 'V',
  workout: 'W',
  membership: 'M'
};
exports.ServiceTypes = ServiceTypes;
var Status = {
  active: 'Y',
  inactive: 'N',
  upcoming: 'upcoming',
  completed: 'completed'
};
exports.Status = Status;
var EntityStatus = {
  active: 'A',
  pending: 'P',
  canceled: 'C',
  waitingList: 'WL',
  reimbursed: 'R'
};
exports.EntityStatus = EntityStatus;
var PaidStatus = {
  yes: 'Y',
  no: 'N'
};
exports.PaidStatus = PaidStatus;
var GenderTypes = {
  male: 'M',
  female: 'F'
};
exports.GenderTypes = GenderTypes;
var PricingModel = {
  total: 'total',
  session: 'session',
  hour: 'hour',
  custom: 'custom'
};
exports.PricingModel = PricingModel;
var FileTypes = {
  document: 'document',
  image: 'image',
  video: 'video',
  audio: 'audio',
  gif: 'gif'
};
exports.FileTypes = FileTypes;
var AgreementTypes = {
  sharing: 'sharing',
  email: 'email',
  marketing: 'marketing'
};
exports.AgreementTypes = AgreementTypes;
var ProviderTypes = {
  centers: 'C',
  professionals: 'P'
};
exports.ProviderTypes = ProviderTypes;
var ProviderMainCategories = {
  C: 1,
  P: 2
};
exports.ProviderMainCategories = ProviderMainCategories;
var SubscriptionPackages = {
  grow: 1,
  video: 2,
  allInOne: 3
};
exports.SubscriptionPackages = SubscriptionPackages;
var Languages = {
  italian: 'Italian',
  english: 'English',
  swedish: 'Swedish'
};
exports.Languages = Languages;
var ResourceTypes = {
  collaborator: 'collaborator',
  director: 'director',
  manager: 'manager',
  owner: 'owner'
};
exports.ResourceTypes = ResourceTypes;
var ResourceTypeId = {
  human: 1,
  physical: 2
};
exports.ResourceTypeId = ResourceTypeId;