import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Block, DateTimePicker, FlexRow, Form, HiddenField, PaperBlock, RadioGroupBox, Spinner, TextBox } from '@guryou/html-components';
import { stateIsLoading, updateUserProfile, useCurrentUser, useGender } from '@guryou/redux';
import Agreements from './Agreements';
import ChangePassword from './ChangePassword';
import UserImage from './UserImage';

const EditProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const update = useSelector(state => state.user.update);

  const genderOptions = useGender();

  const { user } = useCurrentUser();

  const handleSubmit = values => {
    dispatch(updateUserProfile(values));
  };

  if (!user || stateIsLoading(update)) {
    return <Spinner />;
  }

  return (
    <Block xs={12} marginLeft="auto" marginRight="auto" marginTop={1}>
      <ChangePassword />
      <UserImage acc={user} />
      <Form onSubmit={handleSubmit}>
        <FlexRow xs={12} justifyContent="space-around">
          <PaperBlock xs={7} display="flex" justifyContent="space-around" flexWrap="wrap">
            <FlexRow xs={4} justifyContent="center">
              <HiddenField name="role" value={user.role} />
              <TextBox name="name" label={t('GENERAL.username')} value={user.name} />
              <TextBox name="email" label={t('GENERAL.email')} value={user.email} required={true} email={true} />
              <TextBox name="firstname" label={t('GENERAL.name')} value={user.firstname} />
              <TextBox name="lastname" label={t('GENERAL.surname')} value={user.lastname} />
              <RadioGroupBox row={true} name="gender" value={user.gender} options={genderOptions} />
              <DateTimePicker dateFieldName="birthday" label={t('GENERAL.dateofbirth')} value={user.birthday} disablePast={false} />
            </FlexRow>
            <FlexRow xs={4} justifyContent="center">
              <TextBox name="address" label={t('GENERAL.address')} value={user.address} />
              <TextBox name="city" label={t('GENERAL.city')} value={user.city} />
              <TextBox name="postcode" label={t('GENERAL.postalCode')} value={user.postcode} />
              <TextBox name="country" label={t('GENERAL.national')} value={user.country} />
              <TextBox name="phone" label={t('GENERAL.phone')} value={user.phone} />
            </FlexRow>
          </PaperBlock>
          <Agreements agreements={user?.agreements} />
        </FlexRow>
      </Form>
    </Block>
  );
};

export default EditProfile;
