import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileUploader, Form, HiddenField, resetModal, Spinner } from '@guryou/html-components';
import { changeUserAvatar, getCurrentUserAccount, resetState, stateIsLoaded, stateIsLoading, UPLOAD_FILE, uploadFileToS3 } from '@guryou/redux';

const UserImageForm = () => {
  const [image, setImages] = useState();
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const upload = useSelector(state => state.files.upload);
  const { changeAvatar } = useSelector(state => state.user);

  useEffect(() => {
    if (stateIsLoaded(upload)) {
      dispatch(resetState(UPLOAD_FILE));
      dispatch(getCurrentUserAccount(true));
      dispatch(resetModal());
    }
  }, [dispatch, upload, upload.version]);

  useEffect(() => {
    if (file && stateIsLoaded(changeAvatar)) {
      dispatch(uploadFileToS3(file, changeAvatar.data?.presignedUrl));
    }
  }, [changeAvatar, changeAvatar.version, dispatch, file]);

  const handleSubmit = () => {
    const fileType = image[0].name.split('.')[1];
    setFile(image[0]);
    dispatch(changeUserAvatar(fileType));
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {stateIsLoading(changeAvatar) || stateIsLoading(upload) ? (
          <Spinner />
        ) : (
          <>
            <FileUploader acceptedFiles={['image/*']} onChange={data => setImages(data)} filesLimit={1} />
            <HiddenField name="imagesX" value="0" />
            <HiddenField name="imagesY" value="0" />
          </>
        )}
      </Form>
    </>
  );
};

export default UserImageForm;
