import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentSettings } from '@guryou/core';
import { useEffectOnce, useUpdateEffect } from '@guryou/hooks';
import {
  Block,
  ContainedButton,
  DateTimePicker,
  FlexColumn,
  FlexRow,
  Form,
  FormActions,
  FullScreenSpinner,
  SelectOption,
  ValidationSummary,
} from '@guryou/html-components';
import {
  createReservationAsAnonymous,
  getPersonnelAvailabilityDates,
  getPersonnelAvailabilitySlots,
  resetPersonnelAvailabilitySlots,
  stateIsLoaded,
  stateIsLoading,
  useEffectOnSuccess,
} from '@guryou/redux';
import { getReservationInitialDate, isCalendarDayNotAvailable, parseAsBool, parseDate, printPrice, printTime } from '@guryou/utils';
import WidgetContactForm from './WidgetContactForm';
import { WidgetContext } from './WidgetContext';

const WidgetReservation = ({ subService }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { error, setError, resources, acceptPayments, setActiveServiceId, setActiveSubServiceId } = useContext(WidgetContext);
  const { personnelTimeSlots, personnelDateSlots } = useSelector(state => state.widget);

  const filteredResources = resources
    .filter(x => subService.resources.includes(x.id))
    .map(x => ({
      value: x.id,
      label: x.name || x.email,
    }));

  const [localData, setLocalData] = useState({
    resources: filteredResources,
    serviceId: null,
    subServiceId: subService.id,
    resourceId: filteredResources.length === 1 ? filteredResources[0].value : null,
    date: getReservationInitialDate(new Date(), personnelDateSlots),
  });

  useEffect(() => {
    return () => {
      dispatch(resetPersonnelAvailabilitySlots());
    };
  }, [dispatch]);

  useEffectOnce(() => {
    if (localData.subServiceId && localData.resourceId) {
      dispatch(getPersonnelAvailabilityDates(localData.subServiceId, localData.resourceId));
    }
  });

  useUpdateEffect(() => {
    if (localData.subServiceId && localData.resourceId && !stateIsLoaded(personnelDateSlots)) {
      dispatch(getPersonnelAvailabilityDates(localData.subServiceId, localData.resourceId));
    } else if (localData.subServiceId && localData.resourceId && localData.date) {
      dispatch(getPersonnelAvailabilitySlots(localData.subServiceId, localData.resourceId, localData.date));
    } else {
      dispatch(resetPersonnelAvailabilitySlots());
    }
  }, [localData.subServiceId, localData.resourceId, localData.date]);

  useUpdateEffect(() => {
    stateIsLoaded(personnelDateSlots) && setLocalData(o => ({ ...o, date: getReservationInitialDate(new Date(), personnelDateSlots) }));
  }, [personnelDateSlots.version]);

  useEffectOnSuccess(
    state => state.widget.reservationCreate,
    () => {
      setActiveSubServiceId(null);
      setActiveServiceId(null);
    }
  );

  const onSubmit = ({ email, firstname, lastname, phone, resourceId, reservationDate, reservationTime, onlyBook }) => {
    const dateTimeFrom = parseDate(`${reservationDate}T${reservationTime}`).toISOString();
    if (!email || !resourceId || !dateTimeFrom) {
      setError(t('VALIDATIONS.missingFields'));
      return;
    }
    const reservation = {
      email,
      firstname,
      lastname,
      phone,
      dateTimeFrom,
      resourceId: +resourceId,
      subServiceId: subService.id,
      onlyBook: parseAsBool(onlyBook),
    };
    dispatch(createReservationAsAnonymous(reservation));
  };

  let errorContent = null;
  if (error) {
    if (Array.isArray(error)) {
      errorContent = <ValidationSummary marginLeft={0} errorMessage={t(`VALIDATIONS.${error[0].message}`)} />;
    }
  }

  return (
    <Form onSubmit={data => onSubmit(data)} actions={false} parserVersion={2} stateSelector={state => state.widget.reservationCreate}>
      <FlexColumn mdFlexDirection="row" flexWrap="wrap">
        {stateIsLoading(personnelTimeSlots) && <FullScreenSpinner />}

        <FlexRow xs={12}>
          <SelectOption
            xs={12}
            md={6}
            label={t('PROFILE.resource')}
            required={true}
            name="resourceId"
            value={localData.resourceId}
            options={localData.resources}
            onChange={value => {
              dispatch(resetPersonnelAvailabilitySlots());
              setLocalData(o => ({ ...o, resourceId: +value }));
            }}
            fullWidth={true}
          />
          <DateTimePicker
            xs={12}
            md={3}
            disabled={!personnelDateSlots.data}
            value={localData.date}
            dateFieldName="reservationDate"
            label={t('RESERVATIONS.reservationDate')}
            onChange={val => setLocalData(o => ({ ...o, date: val }))}
            shouldDisableDate={date => isCalendarDayNotAvailable(date, personnelDateSlots)}
            errorMessage={stateIsLoaded(personnelTimeSlots) && !personnelTimeSlots.data.length && t('RESERVATIONS.noTimeSlotsAvailable')}
            fullWidth={true}
          />
          {(!stateIsLoaded(personnelTimeSlots) || !!personnelTimeSlots.data.length) && (
            <SelectOption
              xs={12}
              md={3}
              disabled={!stateIsLoaded(personnelTimeSlots) || !personnelTimeSlots.data?.length}
              label={t('RESERVATIONS.reservationTime')}
              required={true}
              name="reservationTime"
              options={personnelTimeSlots.data?.map(x => ({ value: x, label: printTime(x) }))}
              fullWidth={true}
            />
          )}
        </FlexRow>
      </FlexColumn>

      <WidgetContactForm />
      <Block xs={12} md={6}>
        {errorContent}
      </Block>
      <FormActions>
        <FlexRow xs={12} md={6} margin="0 auto">
          {[PaymentSettings.NoPayments, PaymentSettings.Optional].includes(acceptPayments) && (
            <ContainedButton
              name="onlyBook"
              value="true"
              type="submit"
              color="primary"
              text={`${t('BOOK_WIDGET.book')} ${printPrice(subService.price)}`}
            />
          )}
          {[PaymentSettings.OnlineOnly, PaymentSettings.Optional].includes(acceptPayments) && (
            <ContainedButton type="submit" color="primary" text={`${t('GENERAL.payNow')} ${printPrice(subService.detailPrice.price)}`} />
          )}
        </FlexRow>
      </FormActions>
    </Form>
  );
};

export default WidgetReservation;
