import React from 'react';
import { Block, FlexColumn, FlexRow, SmallText } from '@guryou/html-components';
import { boxShadow, extraLargeBorderRadius } from '@guryou/html-theme/src/constants';
import { information } from './LogoInformation';

const Logos = () => {
  return (
    <FlexRow justifyContent="space-around" margin={10} xs={12} marginLeft="auto" marginRight="auto">
      {information.map(logo => (
        <FlexColumn margin={2} width={120} minWidth={90} key={logo.id}>
          <Block
            backgroundImage={`url('${logo.image}')`}
            margin={0}
            borderRadius={extraLargeBorderRadius}
            boxShadow={boxShadow}
            width={90}
            height={90}
            background-repeat="no-repeat"
            background-position="center center"
            background-size={!logo.image.includes('http') ? '70% 70%' : 'cover'}
          ></Block>
          <SmallText textAlign="center" color={logo.color} marginTop={2}>
            {logo.title}
          </SmallText>
        </FlexColumn>
      ))}
    </FlexRow>
  );
};

export default Logos;
