import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ALL_CONSTANT, EntityTypes, Status } from '@guryou/core';
import { Spinner } from '@guryou/html-components';
import { getMyOrders, stateIsLoaded, stateIsNotInitialized } from '@guryou/redux';
import Slider from 'components/slider';
import AllReservationsTable from './AllReservationsTable';
import BreadCrumb from './breadcrumb';
import ServiceCards from './ServiceCards';

const Reservations = () => {
  const dispatch = useDispatch();

  const [view, setView] = useState(ALL_CONSTANT);
  const [status, setStatus] = useState(Status.upcoming);
  const [sliderView, setSliderView] = useState(false);
  const myOrders = useSelector(state => state.myOrders);
  const { data } = myOrders;

  useEffect(() => {
    if (stateIsNotInitialized(myOrders)) {
      dispatch(getMyOrders());
    }
  }, [dispatch, myOrders]);

  if (!stateIsLoaded(myOrders)) {
    return <Spinner />;
  }

  const checkView = type => view === ALL_CONSTANT || view === type;

  const cards = (
    <>
      {checkView(EntityTypes.service) && (
        <ServiceCards view={EntityTypes.service} data={data.filter(x => x.type === EntityTypes.service)} status={status} isNotVideo={true} />
      )}
      {checkView(EntityTypes.course) && (
        <ServiceCards view={EntityTypes.course} data={data.filter(x => x.type === EntityTypes.course)} status={status} isNotVideo={true} />
      )}
      {checkView(EntityTypes.event) && (
        <ServiceCards view={EntityTypes.event} data={data.filter(x => x.type === EntityTypes.event)} status={status} isNotVideo={true} />
      )}
      {checkView(EntityTypes.video) && (
        <ServiceCards view={EntityTypes.video} data={data.filter(x => x.type === EntityTypes.video)} status={status} />
      )}
    </>
  );

  return (
    <>
      <Slider sliderStartingView={sliderView} onChange={setSliderView} />
      <BreadCrumb onChangeService={setView} onChangeStatus={setStatus} />
      {sliderView ? cards : <AllReservationsTable view={view} status={status} obj={myOrders} />}
    </>
  );
};

export default Reservations;
