import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Block,
  CheckBox,
  ContainedButton,
  FlexRow,
  Form,
  ImageMedium,
  LinkButton,
  PasswordBox,
  SmallText,
  TextBox,
  WarningText,
} from '@guryou/html-components';
import { signUp } from '@guryou/redux';
import { useTheme } from '@material-ui/core/styles';
import Logo from 'assets/img/logo.png';

const SignUp = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const errorRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    if (buttonRef && buttonRef.current) {
      buttonRef.current.disabled = true;
    }
  }, []);

  const handleSubmit = data => {
    const { email, password, confirmPassword, emailMarketing, marketing } = data;

    if (password.length < 8) {
      errorRef.current.innerText = t('NOTIFICATIONS.must8char');
    } else if (confirmPassword !== password) {
      errorRef.current.innerText = t('NOTIFICATIONS.passNotSame');
    } else {
      dispatch(signUp(email, password, emailMarketing, marketing));
    }
  };

  const handleChange = data => {
    const { email, password, confirmPassword, terms } = data;
    if (buttonRef.current) {
      if (email && password && confirmPassword && terms) {
        buttonRef.current.disabled = false;
      } else {
        buttonRef.current.disabled = true;
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit} onChange={handleChange} actions={false}>
      <Block xs={9} marginLeft="auto" marginRight="auto">
        <ImageMedium marginLeft="auto" marginRight="auto" src={Logo} />
        <TextBox type="email" name="email" label="Email" />
        <PasswordBox name="password" label="Password" />
        <PasswordBox name="confirmPassword" label="Confirm Password" />
        <CheckBox
          name="terms"
          label={
            <SmallText>
              {`${t('MODALS.policyCheckbox')} `}
              <LinkButton
                color={theme.palette.primary.main}
                to="https://www.iubenda.com/privacy-policy/39480488"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('MODALS.privacyPolicy')}
              </LinkButton>
              {` ${t('MODALS.and')} `}
              <LinkButton
                color={theme.palette.primary.main}
                to="https://www.iubenda.com/terms-and-conditions/8084171"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('MODALS.terms')}.
              </LinkButton>
            </SmallText>
          }
        />
      </Block>

      <SmallText maxWidth={400} width="90%" margin="auto" textAlign="justify">
        {t('MODALS.agreementText')}
      </SmallText>

      <FlexRow width="73%" justifyContent="space-around" marginTop={0} marginLeft="auto" marginRight="auto">
        <Block xs={5}>
          <CheckBox name="emailMarketing" label={<SmallText>{t('MODALS.mailCheckbox')}</SmallText>} className="m-0" labelClassName="centered" />
        </Block>
        <Block xs={7}>
          <CheckBox name="marketing" label={<SmallText>{t('MODALS.marketingCheckbox')}</SmallText>} className="m-0" labelClassName="centered" />
        </Block>
      </FlexRow>
      <WarningText ref={errorRef}></WarningText>
      <FlexRow justifyContent="center" marginTop={5} marginBottom={5}>
        <ContainedButton color="primary" ref={buttonRef} className={`mt-2`} type="submit" text={t('MODALS.completeRegistration')} />
      </FlexRow>
    </Form>
  );
};

export default SignUp;
