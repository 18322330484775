import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import TopNavigation from 'components/navigation';
import Footer from './components/Footer';
import NewsLetters from './components/NewsLetters';
import HomePage from './modules/homepage';
import Profile from './modules/profile';
import Read from './modules/read';
import Videos, { SingleVideo } from './modules/videos';
import WidgetContainer from './modules/widget';

const Routes = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router>
      <TopNavigation />
      <Route path="/read" component={Read} />
      <Route path="/book" component={WidgetContainer} />
      <Route path="/videos/:videoId" component={SingleVideo} />
      <Route exact path="/videos" component={Videos} />
      <Route path="/profile" component={Profile} />
      <Route exact path="/" component={HomePage} />
      <NewsLetters />
      <Footer />
    </Router>
  );
};

export default Routes;
