export const information = [
  {
    id: '1',
    description: `A Garbagnate, alle porte di Milano, una nuova realtà incentrata sulla danza e sul fitness 2.0, Studio17. Una location contemporanea distribuita su 1200 mq incentrata sul concetto di versatilità, luminosa ed accogliente. Si distribuisce su tre livelli con 4 sale dedicate alla danza ed una sala dedi...

Via G. Rossa, 17, 20024 Garbagnate Milanese MI, ItaliaA Garbagnate, alle porte di Milano, una nuova realtà incentrata sulla danza e sul fitness 2.0, Studio17. Una location contemporanea distribuita su 1200 mq incentrata sul concetto di versatilità, luminosa ed accogliente. Si distribuisce su tre livelli con 4 sale dedicate alla danza ed una sala dedi...

Via G. Rossa, 17, 20024 Garbagnate Milanese MI, ItaliaA Garbagnate, alle porte di Milano, una nuova realtà incentrata sulla danza e sul fitness 2.0, Studio17. Una location contemporanea distribuita su 1200 mq incentrata sul concetto di versatilità, luminosa ed accogliente. Si distribuisce su tre livelli con 4 sale dedicate alla danza ed una sala dedi...

Via G. Rossa, 17, 20024 Garbagnate Milanese MI, ItaliaoverflowY="scrollate Milanese MI, ItaliaoverflowY="scrollate Milanese MI, ItaliaoverflowY="scrollate Milanese MI, ItaliaoverflowY="scroll"`,
    image: `https://prodapibook.guryou.com/assets/images/providers/574-IMG_9968%202.JPG`,
    header: 'Palestre',
    footer: 'Palestre',
  },
  {
    id: '2',
    description: `Via Raimondo Annecchino, 85, 63011 Pozzuoli NA, Italia`,
    image: `https://prodapibook.guryou.com/assets/images/providers/244-essere%20e%20benessere%20logo.png`,
    header: 'Palestre',
    footer: 'Palestre',
  },
  {
    id: '3',
    description: ` vanta una storia lunga più di un secolo nel settore della cosmesi e della profumeria. L'azienda fu fondata nel 1910 dal giovane e intraprendente Michele Pepino, determinato a sviluppare la sua passione per i prodotti di bellezza e per la cura della persona come essenze profumate, ciprie e brillantin...
          Via Mariano D'Ayala, 10, 80121 Napoli NA, Italy"`,
    image: `https://prodapibook.guryou.com/assets/images/providers/195-via_chiaia293.jpg`,
    header: 'Palestre',
    footer: 'Palestre',
  },
  {
    id: '4',
    description: `Da sempre a vostra disposizione per consigliarvi profumi, creme per il viso e trattamenti in un ambiente accogliente e meraviglioso.

Parma, Province of Parma, Italy`,
    image: `https://prodapibook.guryou.com/assets/images/providers/579-Profumeria.jpg`,
    header: 'Palestre',
    footer: 'Palestre',
  },
];
