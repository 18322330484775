import React from 'react';
import { CenterMediumContent, FlexColumn, GooglePlay, ImageMedium, Itunes } from '@guryou/html-components';

const DownloadApp = () => (
  <CenterMediumContent>
    <FlexColumn>
      <a href="https://play.google.com/store/apps/details?id=com.guryou.userapp&hl=en">
        <ImageMedium src={GooglePlay} alt="Google Play Icon" width="250px" marginTop={4} />
      </a>
      <a href="https://itunes.apple.com/se/app/guryou/id1331878309?l=en&mt=8">
        <ImageMedium src={Itunes} alt="Itunes Icon" width="250px" marginTop={4} />
      </a>
    </FlexColumn>
  </CenterMediumContent>
);

export default DownloadApp;
