import defaultImage from '../assets/default-image.png';

var generateImageUrl = function generateImageUrl(url) {
  if (url && url.indexOf('http') !== 0) {
    return "".concat(process.env.REACT_APP_IMAGES_HOST_ENDPOINT).concat(url);
  }

  return url || defaultImage;
};

var generatePublicImageUrl = function generatePublicImageUrl(url) {
  var returnNoUrl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (url && url.indexOf('http') !== 0 && !returnNoUrl) {
    return "".concat(process.env.REACT_APP_S3_BUCKET_URL).concat(process.env.REACT_APP_ENVIRONMENT).concat(url);
  }

  if (returnNoUrl) {
    return "".concat(process.env.REACT_APP_S3_BUCKET_URL).concat(process.env.REACT_APP_ENVIRONMENT);
  }

  return url || defaultImage;
};

var generateProfileImageUrl = function generateProfileImageUrl(userId) {
  if (!userId) {
    return defaultImage;
  }

  return "".concat(process.env.REACT_APP_IMAGES_HOST_ENDPOINT, "/assets/images/users/").concat(userId, ".png");
};

export { generateImageUrl, generateProfileImageUrl, generatePublicImageUrl };