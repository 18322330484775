import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ALL_CONSTANT, EntityTypes, Status } from '@guryou/core';
import {
  Block,
  EmptyPage,
  LittleCircle,
  Modal,
  Modals,
  MultipleItemsContainer,
  openModal,
  OutlinedButton,
  resetModal,
  TableCellBody,
  TableRowBody,
} from '@guryou/html-components';
import { dateIsAfter, dateIsBefore, printDate, printPrice, printTime } from '@guryou/utils';
import { useTheme } from '@material-ui/core/styles';
import Sessions from './Sessions';

const AllReservationsTable = ({ status, view, obj }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    dispatch(resetModal());
  }, [dispatch]);

  //eslint-disable-next-line
  let data = obj.data.filter(x => view === ALL_CONSTANT || view === x.type);

  data =
    status === Status.upcoming
      ? data.filter(x => x.type === EntityTypes.video || dateIsAfter(x.startTime))
      : data.filter(x => x.type === EntityTypes.video || dateIsBefore(x.startTime));

  const findItem = value => {
    return obj.data.find(x => x.id === value);
  };

  const handleGroupColor = value => {
    return value ? `#${value}` : theme.palette.primary.main;
  };

  const handleDuration = value => {
    const item = findItem(value);
    if (!item || item.type === EntityTypes.video) {
      return '/';
    }
    return `${item.duration} ${item.timeUnit}s`;
  };

  const handleModal = value => {
    const item = findItem(value);
    dispatch(openModal('sessions', { service: item }, `${item.name} (${t('SESSIONS.titlePlural')})`));
  };

  return (
    <Block marginTop={5}>
      <MultipleItemsContainer {...{ ...obj, data }} key="AllReservations" noHeader={true}>
        <EmptyPage>{t('RESERVATIONS.noReservations')}</EmptyPage>
        <TableRowBody>
          <TableCellBody name="id" display={false} />
          <TableCellBody
            name="color"
            label={t('GENERAL.group')}
            render={value => <LittleCircle marginLeft={2} backgroundColor={handleGroupColor(value)}></LittleCircle>}
          />
          <TableCellBody label={t('GENERAL.type')} name="type" />
          <TableCellBody label={t('GENERAL.name')} name="name" />
          <TableCellBody label={t('GENERAL.price')} name="price" render={value => printPrice(value)} />
          <TableCellBody label={t('PROFILE.date')} name="date" render={value => printDate(value)} />
          <TableCellBody label={t('PROFILE.time')} name="date" render={value => printTime(value)} />
          <TableCellBody label={t('PROFILE.duration')} name="id" render={value => handleDuration(value)} />
          <TableCellBody label={t('PROFILE.resource')} name="resourceName" render={value => value || '/'} />
          <TableCellBody
            label={t('SESSIONS.titlePlural')}
            name="sessions"
            render={(value, meta) =>
              value ? <OutlinedButton onClick={() => handleModal(meta.rowData[0])}>{t('SESSIONS.titlePlural')}</OutlinedButton> : '/'
            }
          />
        </TableRowBody>
        <Modals>
          <Modal id="sessions" maxWidth="md">
            <Sessions />
          </Modal>
        </Modals>
      </MultipleItemsContainer>
    </Block>
  );
};

export default AllReservationsTable;
